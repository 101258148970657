import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearLocalStorage, deleteWithToken, getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../API/Api';
import Loader from '../components/Loader';
import EditChatBox from '../components/EditChatBox';
import { FiPlus } from 'react-icons/fi';
import { List, arrayMove } from 'react-movable';
import DotsSixDotted from '../assets/icons/DotsSixDotted.svg';
import EDIT_ICON from '../assets/icons/ChirpfloIcon/edit-icon.svg';
import DELETE_ICON from '../assets/icons/ChirpfloIcon/Delete-icon.svg';
import ADD_ICON from '../assets/icons/ChirpfloIcon/Add-text-icon.svg';
import STAR_ICON from '../assets/icons/ChirpfloIcon-V2.0/star-icon.svg';
import CHIRPFLO_ICON from '../assets/icons/ChirpfloIcon-V2.0/chirpflobold-icon.svg';
import { IoClose } from 'react-icons/io5';
import { subContext } from '../App';
import { IoMdClose, IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

import { FaEdit } from 'react-icons/fa';

const ValidationErrors = {
  empty: {
    bot_name: 'Name required',
    tone: 'Tone required',
  },
};

const TONES = [
  { name: 'Funny' },
  { name: 'Humorous' },
  { name: 'Sales Genius' },
  { name: 'Empathetic' },
  { name: 'Calm' },
  { name: 'Friendly' },
  { name: 'Helpful' },
  { name: 'Mindful' },
  { name: 'Spiritual' },
  { name: 'Comedian' },
];

const ROLES = [{ name: 'Virtual Assistant' }, { name: 'Business Owner' }, { name: 'Sales representative' }, { name: 'Marketing specialist' }, { name: 'Financial manager' }];

const countCharacters = (text) => {
  if (text) {
    return text.length;
  }
  return 0;
};
const maxLength = 5000;

const KnowledgeBaseSetMessage = () => {
  const [values, setValues] = useState({
    bot_name: '',
    tone: '',
    role: '',
    welcome_msg: '',
    agent_rules: '',
    phone_number: '',
    name: '',
    email_address: '',
    call_to_actions: '',
  });
  const [loader, setLoader] = useState(false);
  const [blurPopup, setBlurPopup] = useState(false);
  const [botBubblePopup, setBotBubblePopup] = useState(false);
  const [blurChange, setBlurChange] = useState(false);
  const [botId, setBotId] = useState('');
  const [apiTokenData, setApiTokenData] = useState('');
  const [childData, setChildData] = useState(false);
  const Navigate = useNavigate();
  const { state } = useLocation();
  const concernedElementRef = useRef(null);
  const subsFlag = useContext(subContext);
  const { bot_name, tone, role, welcome_msg, agent_rules } = values;
  const [bgPopup, setBgPopup] = useState(false);

  const [editLeadPopup, setEditLeadPopup] = useState(false);
  const [editLeadClosingPopup, setEditLeadClosingPopup] = useState(false);
  const [informationValues, setInformationValues] = useState({
    id: '',
    welcome_lead_message: '',
    lead_status: false,
    positive_lead_choice: '',
    continue_lead_choice: '',
    closing_lead_message: '',
    positive_lead_closing_choice: '',
    continue_lead_closing_choice: '',
    generate_lead_choice: '',
    lead_form: '',
  });
  const {
    welcome_lead_message,
    lead_status,
    positive_lead_choice,
    continue_lead_choice,
    closing_lead_message,
    positive_lead_closing_choice,
    continue_lead_closing_choice,
    generate_lead_choice,
    lead_form,
  } = informationValues;

  const [errors, setErrors] = useState({
    bot_name: '',
    tone: '',
  });

  const FullName = JSON.parse(getLocalStorage('FullName'));

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value.length >= maxLength) {
      toast.dismiss();
      toast.info('Character limit exceeded! Please keep it under 5000 characters.');
    }

    setValues({ ...values, [name]: value || null });
    setErrors({ ...errors, [name]: null });
    setBlurChange(true);
  };

  const handleBlurClick = (event) => {
    if (blurChange) {
      if (concernedElementRef.current && concernedElementRef.current.contains(event.target)) {
        console.log('Clicked Inside');
      } else {
        setBlurPopup(true);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      getBotMessageDetails(JSON.parse(token));
      getInformationValues(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, [childData]);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (editLeadPopup || editLeadClosingPopup) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  }, [editLeadPopup, editLeadClosingPopup]);

  const handleChildData = (data) => {
    setChildData(data);
  };

  const getBotMessageDetails = (token) => {
    setLoader(true);
    getWithToken('company_base/', token)
      .then((response) => {
        if (response.status === 201) {
          setBotId(response?.data?.id);
          setValues({
            ...values,
            bot_name: response?.data?.bot_name,
            tone: response?.data?.tone,
            role: response?.data?.role,
            welcome_msg: response?.data?.welcome_msg || null,
            agent_rules: response?.data?.agent_rules || null,
            email_address: response?.data?.email_address,
            name: response?.data?.name,
            phone_number: response?.data?.phone_number,
            call_to_actions: response?.data?.call_to_actions,
          });
          setLoader(false);
          setChildData(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
          setLoader(false);
        }
      })
      .catch((error) => {
        // toast.error('Something went wrong');
        setLoader(false);
      });
  };

  const handleSubmit = async (valueName) => {
    const tempErrors = { ...errors };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        tempErrors[key] = ValidationErrors.empty[key];
      }
    });

    setErrors(tempErrors);

    if (Object.values(tempErrors).filter((obj) => !!obj).length > 0) {
      return false;
    }

    const payload = {
      bot_name,
      tone,
      role,
      welcome_msg,
      agent_rules,
    };
    try {
      const response = await patchWithToken(`company_base/${botId}/`, apiTokenData, payload);
      if (response.status === 200) {
        toast.dismiss();
        toast.success(response.message);
        setBlurChange(false);
        getBotMessageDetails(apiTokenData);
        subsFlag.setUpdateValue('1');
      } else if (response.status === 400) {
        toast.error(response.message);
      } else if (response.code === 'token_not_valid') {
        clearLocalStorage();
      } else {
        toast.error('Please create the company.');
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleBgDelete = async () => {
    try {
      const response = await deleteWithToken('Widget_bg_ColorAPI/', apiTokenData);
      if (response.status === 200) {
        setBgPopup(false);
        toast.success(response.message);
      } else if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInformationValues = (token) => {
    getWithToken('LeadsAPI', token)
      .then((response) => {
        if (response.status === 200) {
          const leadInformationQuestions = response?.data?.lead_information?.map((question) => {
            return {
              id: question.id,
              question: question.question,
              lead_info_status: question.lead_info_status,
              isQuestionEditable: question.isQuestionEditable,
              filedName: question.filedName,
            };
          });
          setQuestions(leadInformationQuestions);
          subsFlag.setUpdateValue('1');
          setInformationValues({
            ...informationValues,
            id: response?.data?.id,
            welcome_lead_message: response?.data?.welcome_lead_message,
            lead_status: response?.data?.lead_status,
            positive_lead_choice: response?.data?.positive_lead_choice,
            continue_lead_choice: response?.data?.continue_lead_choice,
            closing_lead_message: response?.data?.closing_lead_message,
            positive_lead_closing_choice: response?.data?.positive_lead_closing_choice,
            continue_lead_closing_choice: response?.data?.continue_lead_closing_choice,
            generate_lead_choice: response?.data?.generate_lead_choice,
            lead_form: response?.data?.lead_form,
          });
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        // toast.error('Something went wrong');
      });
  };

  // const informationHandleChange = ({ target: { name, checked, value } }) => {
  //   const updatedValues = {
  //     ...informationValues,
  //     [name]: name === 'lead_status' ? checked : value || null,
  //   };
  //   setInformationValues(updatedValues);

  //   if (name === 'lead_status') {
  //     handleLeadStatusSubmit(updatedValues);
  //   }
  // };

  // const handleInformationSubmit = (e) => {
  //   e.preventDefault();
  //   setLoader(true);

  //   const body = {
  //     welcome_lead_message,
  //     lead_status: informationValues.lead_status || false,
  //     positive_lead_choice: informationValues.positive_lead_choice || 'Yes',
  //     continue_lead_choice: informationValues.continue_lead_choice || 'I have a few questions first',
  //     closing_lead_message,
  //     positive_lead_closing_choice: informationValues.positive_lead_closing_choice || "That's All",
  //     continue_lead_closing_choice: informationValues.continue_lead_closing_choice || 'I have 1 more question',
  //     generate_lead_choice: informationValues.generate_lead_choice || 'Generate Lead',
  //     lead_form: informationValues.lead_form || false,
  //   };

  //   const apiCall = informationValues.id ? patchWithToken : postWithToken;

  //   apiCall('LeadsAPI', apiTokenData, body)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         getInformationValues(apiTokenData);
  //         setLoader(false);
  //         toast.dismiss();
  //         toast.success(response.message);
  //       } else if (response.status === 400) {
  //         toast.error(response.message);
  //         setLoader(false);
  //       } else if (response.code === 'token_not_valid') {
  //         clearLocalStorage();
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //     });
  // };

  // const handleLeadStatusSubmit = (formData) => {
  //   setLoader(true);

  //   const body = {
  //     welcome_lead_message,
  //     lead_status: formData.lead_status || false,
  //   };

  //   const apiCall = formData.id ? patchWithToken : postWithToken;

  //   apiCall('LeadsAPI', apiTokenData, body)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         getInformationValues(apiTokenData);
  //         setLoader(false);
  //         toast.dismiss();
  //         toast.success(response.message);
  //       } else if (response.status === 400) {
  //         toast.error(response.message);
  //         setLoader(false);
  //       } else if (response.code === 'token_not_valid') {
  //         clearLocalStorage();
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //     });
  // };

  const informationHandleChange = ({ target: { name, checked, value } }) => {
    const updatedValues = {
      ...informationValues,
      [name]: name === 'lead_status' || name === 'lead_form' ? checked : value || null,
    };
    setInformationValues(updatedValues);

    if (name === 'lead_status') {
      handleLeadStatusSubmit(updatedValues);
    } else if (name === 'lead_form') {
      handleLeadFormSubmit(updatedValues);
    }
  };

  const handleInformationSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    const body = {
      welcome_lead_message,
      lead_status: informationValues.lead_status || false,
      positive_lead_choice: informationValues.positive_lead_choice || 'Yes',
      continue_lead_choice: informationValues.continue_lead_choice || 'I have a few questions first',
      closing_lead_message,
      positive_lead_closing_choice: informationValues.positive_lead_closing_choice || "That's All",
      continue_lead_closing_choice: informationValues.continue_lead_closing_choice || 'I have 1 more question',
      generate_lead_choice: informationValues.generate_lead_choice || 'Generate Lead',
      lead_form: informationValues.lead_form || false,
    };

    const apiCall = informationValues.id ? patchWithToken : postWithToken;

    apiCall('LeadsAPI', apiTokenData, body)
      .then((response) => {
        if (response.status === 200) {
          getInformationValues(apiTokenData);
          setLoader(false);
          toast.dismiss();
          toast.success(response.message);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleLeadStatusSubmit = (formData) => {
    setLoader(true);

    const body = {
      welcome_lead_message,
      lead_status: formData.lead_status || false,
    };

    const apiCall = formData.id ? patchWithToken : postWithToken;

    apiCall('LeadsAPI', apiTokenData, body)
      .then((response) => {
        if (response.status === 200) {
          getInformationValues(apiTokenData);
          setLoader(false);
          toast.dismiss();
          toast.success(response.message);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleLeadFormSubmit = (formData) => {
    setLoader(true);

    const body = {
      lead_form: formData.lead_form || false,
    };

    const apiCall = formData.id ? patchWithToken : postWithToken;

    apiCall('LeadsAPI', apiTokenData, body)
      .then((response) => {
        if (response.status === 200) {
          getInformationValues(apiTokenData);
          setLoader(false);
          toast.dismiss();
          toast.success(response.message);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const [questions, setQuestions] = useState([{ id: 1, question: '', lead_info_status: false, isQuestionEditable: false }]);
  const addQuestion = () => {
    const newQuestion = {
      question: '',
      lead_info_status: false,
      isQuestionEditable: false,
    };

    postWithToken('Lead_Infos_API', apiTokenData, newQuestion)
      .then((response) => {
        if (response.status === 200) {
          getInformationValues(apiTokenData);
          setLoader(false);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  var deboounce = null;
  const handleQuestionChange = (e, id) => {
    if (deboounce != null) {
      clearTimeout(deboounce);
    }

    deboounce = setTimeout(
      (e, id) => {
        const body = {
          question: e.target.value,
          lead_info_status: questions?.lead_info_status,
        };

        patchWithToken(`Lead_Infos_API/${id}`, apiTokenData, body)
          .then((response) => {
            if (response.status === 200) {
              getInformationValues(apiTokenData);
            } else if (response.status === 400) {
              toast.error(response.message);
            } else if (response.code === 'token_not_valid') {
              clearLocalStorage();
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      },
      1000,
      e,
      id
    );
  };

  const handleAddQuestion = (id) => {
    const updatedQuestions = [...questions];
    const questionIndex = updatedQuestions.findIndex((q) => q.id === id);

    if (questionIndex !== -1) {
      const updatedQuestion = { ...updatedQuestions[questionIndex] };
      updatedQuestion.lead_info_status = !updatedQuestion.lead_info_status;
      updatedQuestions[questionIndex] = updatedQuestion;
      const body = {
        question: updatedQuestion.question,
        lead_info_status: updatedQuestion.question !== '' ? updatedQuestion.lead_info_status : false,
      };

      patchWithToken(`Lead_Infos_API/${id}`, apiTokenData, body)
        .then((response) => {
          if (response.status === 200) {
            setQuestions(updatedQuestions);
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const handleQuestionDelete = (id) => {
    if (questions.length === 1) {
      toast.dismiss();
      toast.warning('At least one question must remain.');
      return;
    }
    deleteWithToken(`Lead_Infos_API/${id}`, apiTokenData)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          getInformationValues(apiTokenData);
          toast.success(response.message);
        } else if (response.code === 'token_not_valid') {
          setLoader(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
        setLoader(false);
      });
  };
  const handleOrderList = (Data) => {
    Data.forEach((obj, index) => {
      const body = {
        order_id: index + 1,
      };
      patchWithToken(`Lead_Infos_API/${obj?.id}`, apiTokenData, body)
        .then((response) => {
          if (response.status === 200) {
            setLoader(false);
            getInformationValues(apiTokenData);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    });
  };

  const handlePopupContentClick = (event) => {
    event.stopPropagation();
  };

  const handleEnhancedAi = (agent_rules) => {
    setLoader(true);

    if (!agent_rules?.trim()) {
      toast.error('System Prompt cannot be blank');
      setLoader(false);
      return;
    }

    postWithToken('enhance_prompt', apiTokenData, { prompt: agent_rules })
      .then((response) => {
        if (response.status === 200) {
          let trimmedResponse = response.data;
          if (trimmedResponse.length > 5000) {
            trimmedResponse = trimmedResponse.substring(0, 5000);
          }
          getInformationValues(apiTokenData);
          const updatedValues = {
            ...values,
            agent_rules: trimmedResponse,
            // agent_rules: response.data,
          };
          setValues(updatedValues);
          setLoader(false);
          toast.success(response.message);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        toast.error('An error occurred while processing your request.');
        setLoader(false);
      });
  };

  const [recommendationQuestions, setRecommendationQuestions] = useState([{ id: 1, parentId: null, question: '' }]);
  // const [expandedQuestionId, setExpandedQuestionId] = useState(null);

  const handleAddRecommendationQuestion = (parentId) => {
    const newQuestion = { id: Date.now(), parentId, question: '' };
    const filteredQuestions = recommendationQuestions.filter((q) => q.id !== 1);
    setRecommendationQuestions([...filteredQuestions, newQuestion]);
    // setExpandedQuestionId(parentId);
  };

  const handleRecommendationQuestionChange = (id, value) => {
    // if (deboounce != null) {
    //   clearTimeout(deboounce);
    // }

    setRecommendationQuestions(recommendationQuestions.map((question) => (question.id === id ? { ...question, question: value } : question)));
    // deboounce = setTimeout((id, value) => {}, 500, id, value);
  };
  const handleDeleteRecommendationQuestion = (id) => {
    setRecommendationQuestions(recommendationQuestions.filter((question) => question.id !== id));
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-3 pe-sm-3 pe-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='mb-5'>
              <h4 className='text-white opensans-bold mb-4'>Hey, {FullName}</h4>
              <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-justify'>
                Let's make your agents sound how you want them to. Pick a name, personality, role, and give it some rules it must follow. You can always fine-tune and test your
                rules. Just make sure after you make any changes don't forget to RE-Train your agent. ✅
              </p>
            </div>

            <form onSubmit={onFormSubmit} className='px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
              {!botBubblePopup && (
                <div className='chirpflo-box p-3 p-lg-3 p-xl-4 p-xxl-5 input-GPT new'>
                  <div className='bubble-close-icon' style={{ background: '#69dfe1', top: '8px', right: '11px' }} onClick={() => setBotBubblePopup(true)}>
                    <IoMdClose style={{ fontSize: '20px', color: '#000000' }} />
                  </div>
                  <div class='chirpflo-title opensans-regular '>Chirpflo Ai</div>
                  <p class='chirpflo-pro-tip opensans-regular mb-0 font18'>
                    <span className='opensans-bold'> PRO TIP:</span> Use Chirpflo's amazing new Enhance Ai feature to write the perfect prompt. Just type what instructions you want
                    your agent to follow and then click Enhance Ai.
                  </p>
                  <img src={CHIRPFLO_ICON} alt='chirpflo-icon' />
                </div>
              )}
              <div className='chatbox-input-text d-unset input-GPT new'>
                <label className='opensans-medium'>Bot Name*</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='John'
                    className='form-control chatbox-input box-shadow-hover'
                    id='bot_name'
                    name='bot_name'
                    value={bot_name}
                    autoComplete='off'
                    onChange={handleChange}
                  />
                  <div className='chat-error'>{errors.bot_name && <p className='text-danger insta-smart-error'>{errors.bot_name}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Role</label>
                <div className='chatbox-input-field'>
                  <select className='form-control box-shadow-hover' name='role' value={role} onChange={handleChange}>
                    <option selected hidden>
                      Choose the Role
                    </option>
                    {ROLES.map((row, i) => (
                      <option key={i}>{row?.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Tone*</label>
                <div className='chatbox-input-field'>
                  <select className='form-control box-shadow-hover' name='tone' value={tone} onChange={handleChange}>
                    <option selected hidden>
                      Choose the Tone
                    </option>
                    {TONES.map((row, i) => (
                      <option key={i}>{row?.name}</option>
                    ))}
                  </select>
                  <div className='chat-error'>{errors.tone && <p className='text-danger insta-smart-error'>{errors.tone}</p>}</div>
                </div>
              </div>
              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Welcome Message</label>
                <div className='chatbox-input-field'>
                  <textarea
                    rows='6'
                    className='form-control chatbox-input h-25 box-shadow-hover'
                    placeholder='Message'
                    id='welcome_msg'
                    name='welcome_msg'
                    value={welcome_msg}
                    autoComplete='off'
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className='chatbox-input-text input-GPT new'>
                <div className='d-flex align-items-center justify-content-between flex-wrap mb-2'>
                  <label className='opensans-medium'>System Prompt / Agent Rule</label>
                  <div className='enhanced-content'>
                    <button className='btn btn-enhanced-ai opensans-regular' onClick={() => handleEnhancedAi(agent_rules)}>
                      Enhance Ai
                      <img src={STAR_ICON} alt='star-icon' className='ps-2 star-icon' />
                    </button>
                  </div>
                </div>
                <div className='chatbox-input-field'>
                  <textarea
                    rows='6'
                    className='form-control chatbox-input h-25 box-shadow-hover'
                    placeholder='Agent Rules'
                    id='agent_rules'
                    name='agent_rules'
                    value={agent_rules}
                    autoComplete='off'
                    onChange={handleChange}
                    maxLength={5000}
                  ></textarea>
                </div>
                <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-end'>({countCharacters(agent_rules) || 0} / 5000)</p>
              </div>
              <div className='my-5 d-flex flex-wrap align-items-center justify-content-between'>
                <button className='btn btn-submit-login rounded opensans-bold' onClick={handleSubmit}>
                  Train Agent
                </button>
                {subsFlag?.subscriptionFlag?.user?.subscription?.name !== 'Pro' && state != null && state?.faqFlag && (
                  <button
                    className='btn btn-submit-login bg-themeLightGreenGradient'
                    onClick={() => Navigate('/chatbot/customizewidget', { state: { faqFlag: true, id: state?.id, length: state?.length } })}
                  >
                    Continue
                  </button>
                )}
              </div>
            </form>
          </div>
          {subsFlag?.subscriptionFlag?.user?.subscription?.name === 'Pro' && (
            <div className='chatbox-field bg-themeLightBlue mt-3'>
              <div>
                <div className='pb-5'>
                  <h3 className='opensans-bold text-white mb-4'>Customized Lead Questions</h3>
                  <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-justify'>
                    Here, you can train your Chirpflo Agent to ask SPECIFIC lead-gen questions tailored to YOUR needs. This can save you HOURS on qualifying prospects. Let Chirpflo
                    do it for you. Then head over to the Generated Leads section on the left and see how Chirpflo's custom-built CRM, Combined with its inbuilt AI summary library
                    ✨, automatically summarizes the whole conversation and gives you the key takeaways to make it a breeze to categorize your customers. Once you are all done,
                    click the SAVE button. 👏
                  </p>
                </div>
                <div className='toogle-body px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
                  <div className='chatbox-input-slider chatbox-input-field input-GPT new'>
                    <h5 className='opensans-medium text-white'>Activate Lead Flow</h5>

                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='lead_status'
                        name='lead_status'
                        checked={informationValues.lead_status}
                        onChange={informationHandleChange}
                      />
                    </div>
                  </div>
                  <div className='chatbox-input-text input-GPT new'>
                    <div className='chatbox-input-field'>
                      <label className='color-theme-darkLightblue p-0 mb-1'>Welcome Message For Lead</label>
                      <textarea
                        rows='6'
                        className='form-control chatbox-input h-50'
                        placeholder='Welcome Message'
                        id='welcome_lead_message'
                        name='welcome_lead_message'
                        value={welcome_lead_message}
                        autoComplete='off'
                        onChange={informationHandleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className='d-flex align-items-center flex-wrap'>
                    <button className='btn btn-submit-login rounded bg-theme-cylindricalBlue' id='firstButton'>
                      {positive_lead_choice || 'Yes'}
                    </button>
                    <button className='btn btn-submit-login rounded bg-theme-cylindricalBlue mt-sm-0 mt-2' id='secondButton'>
                      {continue_lead_choice || 'I have a few questions first'}
                    </button>
                    <div className='knowledge-pair-icon ms-3'>
                      <button className='btn' type='button' onClick={() => setEditLeadPopup(true)}>
                        <img src={EDIT_ICON} alt='edit-icon' />
                      </button>
                    </div>
                  </div>
                  {informationValues?.id !== '' && (
                    <>
                      <div className='chatbox-input-text d-unset mt-5 mb-3'>
                        <h5 className='opensans-medium text-white pb-3'>Lead Information</h5>
                        <div className='d-flex align-items-center'>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <List
                              lockVertically
                              values={questions}
                              onChange={({ oldIndex, newIndex }) => handleOrderList(arrayMove(questions, oldIndex, newIndex))}
                              renderList={({ children, props, isDragged }) => <div {...props}>{children}</div>}
                              renderItem={({ value: obj, index, props, isDragged, isSelected }) => (
                                <div
                                  {...props}
                                  key={obj.id}
                                  className='draggable-item'
                                  onMouseDown={(e) => {
                                    if (e.target.closest('.non-draggable')) {
                                      e.stopPropagation();
                                    }
                                  }}
                                  style={{
                                    ...props.style,
                                    listStyleType: 'none',
                                    color: '#ffffff',
                                    cursor: isDragged ? 'grabbing' : 'inherit',
                                  }}
                                >
                                  <div className={`d-flex align-items-center my-3 w-100 ${recommendationQuestions.length > 0 && 'mb-0'}`}>
                                    <button
                                      type='button'
                                      className='btn p-0 mt-2 me-4 border-0 non-draggable'
                                      aria-label='Close'
                                      onClick={() => handleQuestionDelete(obj.id)}
                                      // disabled={obj?.isQuestionEditable}
                                      // style={{ cursor: obj?.isQuestionEditable && 'not-allowed' }}
                                    >
                                      <img src={DELETE_ICON} alt='delete-icon' style={{ width: '30px', height: '30px' }} />
                                    </button>
                                    <div className='w-100'>
                                      <div className='d-flex align-items-center justify-content-between mb-1'>
                                        <label className='color-theme-darkLightblue p-0'>{`Question ${index + 1 > 9 ? '' : '0'}${index + 1}`}</label>
                                        <div className='form-check form-switch me-4'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            role='switch'
                                            id={`slider-${obj.id}`}
                                            name={`slider-${obj.id}`}
                                            checked={obj.lead_info_status}
                                            onChange={() => handleAddQuestion(obj.id)}
                                          />
                                        </div>
                                      </div>
                                      <div className='input-group custom-input-group mb-3 box-shadow-hover'>
                                        <input
                                          type='text'
                                          placeholder='Add Questions'
                                          className='form-control'
                                          name={`question-${obj.id}`}
                                          defaultValue={obj.question}
                                          onChange={(e) => handleQuestionChange(e, obj.id)}
                                          autoComplete='off'
                                          // disabled={obj?.isQuestionEditable}
                                        />
                                        {obj?.isQuestionEditable && (
                                          <span className='input-group-text' id='basic-addon2'>
                                            <div className='btn-custom btn-custom-border rounded-1'>
                                              <span className='field-name opensans-semiBold '>Fixed</span>
                                            </div>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className='attach-icon mt-2 ms-3 cursor-pointer' data-movable-handle>
                                      <img src={DotsSixDotted} alt='bell-icon' draggable='false' style={{ pointerEvents: 'auto' }} />
                                    </div>

                                    {/* <button
                                      type='button'
                                      className='btn p-0 mt-2 ms-3 border-0 non-draggable'
                                      aria-label='Close'
                                      onClick={() => handleAddRecommendationQuestion(obj.id)}
                                    >
                                      <IoMdAddCircleOutline className='me-3 color-theme-darkLightblue' style={{ width: '30px', height: '30px' }} />
                                    </button> */}
                                  </div>
                                  {recommendationQuestions.length > 0 && (
                                    <div className='recommendation-questions-container'>
                                      {recommendationQuestions
                                        .filter((question) => question.parentId === obj.id)
                                        .map((question, recIndex) => (
                                          <div key={question.id} className='recommendation-question-item'>
                                            <div className='w-100'>
                                              <label className='color-theme-darkLightblue p-0 mb-1'>{`Recommendation Question ${recIndex + 1 > 9 ? '' : '0'}${
                                                recIndex + 1
                                              }`}</label>
                                              <div className='input-group custom-input-group box-shadow-hover'>
                                                <input
                                                  type='text'
                                                  placeholder='Enter new recommendation question'
                                                  className='form-control recommendation-input'
                                                  value={question.question}
                                                  onChange={(e) => handleRecommendationQuestionChange(question.id, e.target.value)}
                                                />
                                              </div>
                                            </div>
                                            <button
                                              type='button'
                                              className='btn p-0 ms-3 mt-4 border-0 non-draggable color-theme-darkLightblue'
                                              aria-label='Delete'
                                              onClick={() => handleDeleteRecommendationQuestion(question.id)}
                                            >
                                              {/* <img src={DELETE_ICON} alt='delete-icon' className='recommendation-icon' /> */}
                                              <MdDeleteOutline className='color-theme-darkLightblue recommendation-icon' />
                                            </button>
                                            {/* <button type='button' className='btn p-0 ms-3 mt-4 border-0 non-draggable' aria-label='Delete'>
                                              <FaEdit className='color-theme-darkLightblue recommendation-icon' />
                                            </button> */}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            />

                            <div className='my-5'>
                              <button className='btn btn-submit-login rounded opensans-bold' onClick={addQuestion}>
                                <FiPlus className='me-3' />
                                Add More Questions
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className='chatbox-input-text d-unset mt-2'>
                    <div className='d-flex align-items-center websitescrape-label'>
                      <label className='scrapeSelected opensans-bold pb-0' htmlFor='lead_form'>
                        <input type='checkbox' id='lead_form' name='lead_form' onChange={informationHandleChange} checked={lead_form} />
                        Lead Form
                      </label>
                    </div>
                  </div>

                  <div className='chatbox-input-text d-unset input-GPT new mt-5'>
                    <div className='chatbox-input-text input-GPT new'>
                      <div className='chatbox-input-field'>
                        <label className='color-theme-darkLightblue p-0 mb-1'>Call To Action</label>
                        <textarea
                          rows='6'
                          className='form-control chatbox-input h-50'
                          placeholder='Closing Message'
                          id='closing_lead_message'
                          name='closing_lead_message'
                          value={closing_lead_message}
                          autoComplete='off'
                          onChange={informationHandleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap align-items-center justify-content-between'>
                    <button className='btn btn-submit-login rounded opensans-bold' onClick={handleInformationSubmit}>
                      Save
                    </button>
                    {state != null && state?.faqFlag && (
                      <button
                        className='btn btn-submit-login bg-themeLightGreenGradient'
                        onClick={() => Navigate('/chatbot/customizewidget', { state: { faqFlag: true, id: state?.id, length: state?.length } })}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 px-sm-3 px-0'>
          <EditChatBox passChildData={handleChildData} ref={concernedElementRef} handleBlurClick={handleBlurClick} />
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
      {blurPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <div className={'popup-head text-center'}>
              <h4>Train Agent Again</h4>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn danger-btn mx-1 text-capitalize'
                data-dismiss='modal'
                onClick={() => {
                  setBlurPopup(false);
                  setBlurChange(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {bgPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <h4 className='opensans-bold'>Are you sure you want to delete?</h4>
            <hr />
            <div className={'popup-head'}>
              <p className='opensans-regular'> </p>
            </div>
            <hr />
            <div className='popup-footer'>
              <button className='btn bg-danger text-white mx-1 text-capitalize' onClick={() => handleBgDelete()}>
                Delete
              </button>
              <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setBgPopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {editLeadPopup && (
        <div className='custom-popup' onClick={() => setEditLeadPopup(false)}>
          <div className='popup-content position-relative' onClick={handlePopupContentClick}>
            <span className='lead-close-icon' onClick={() => setEditLeadPopup(false)}>
              <IoClose />
            </span>
            <div className={'popup-head chatbox-field'}>
              <div className='chatbox-input-text input-GPT new mb-5'>
                <label className='opensans-medium text-white'>Positive Lead Choice</label>
                <div className='chatbox-input-field w-100'>
                  <input
                    type='text'
                    placeholder='Lead choice'
                    className='form-control chatbox-input'
                    name='positive_lead_choice'
                    value={positive_lead_choice}
                    maxLength={30}
                    autoComplete='off'
                    onChange={informationHandleChange}
                  />
                </div>
              </div>
              <div className='chatbox-input-text input-GPT new mb-5'>
                <label className='opensans-medium text-white'>Continue Lead Choice</label>
                <div className='chatbox-input-field  w-100'>
                  <input
                    type='text'
                    placeholder='Lead choice'
                    className='form-control chatbox-input'
                    name='continue_lead_choice'
                    value={continue_lead_choice}
                    maxLength={30}
                    autoComplete='off'
                    onChange={informationHandleChange}
                  />
                </div>
              </div>
            </div>
            <div className='popup-footer'>
              <button className='btn bg-primary mx-1 text-white' onClick={() => setEditLeadPopup(false)}>
                Save
              </button>
              {/* <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setEditPopup(false)}>
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      )}
      {editLeadClosingPopup && (
        <div className='custom-popup' onClick={() => setEditLeadClosingPopup(false)}>
          <div className='popup-content position-relative' onClick={handlePopupContentClick}>
            <span className='lead-close-icon' onClick={() => setEditLeadClosingPopup(false)}>
              <IoClose />
            </span>
            <div className={'popup-head chatbox-field'}>
              <div className='chatbox-input-text input-GPT new mb-5'>
                <label className='opensans-medium text-white'>Lead Closing Choice</label>
                <div className='chatbox-input-field w-100'>
                  <input
                    type='text'
                    placeholder='Lead choice'
                    className='form-control chatbox-input'
                    name='positive_lead_closing_choice'
                    value={positive_lead_closing_choice}
                    maxLength={30}
                    autoComplete='off'
                    onChange={informationHandleChange}
                  />
                </div>
              </div>
              <div className='chatbox-input-text input-GPT new mb-5'>
                <label className='opensans-medium text-white'>Continue Chat Choice</label>
                <div className='chatbox-input-field  w-100'>
                  <input
                    type='text'
                    placeholder='Lead choice'
                    className='form-control chatbox-input'
                    name='continue_lead_closing_choice'
                    value={continue_lead_closing_choice}
                    maxLength={30}
                    autoComplete='off'
                    onChange={informationHandleChange}
                  />
                </div>
              </div>
              <div className='chatbox-input-text input-GPT new mb-5'>
                <label className='opensans-medium text-white'>Generate Chat Choice</label>
                <div className='chatbox-input-field  w-100'>
                  <input
                    type='text'
                    placeholder='Lead choice'
                    className='form-control chatbox-input'
                    name='generate_lead_choice'
                    value={generate_lead_choice}
                    maxLength={30}
                    autoComplete='off'
                    onChange={informationHandleChange}
                  />
                </div>
              </div>
            </div>
            <div className='popup-footer'>
              <button className='btn bg-primary mx-1 text-white' onClick={() => setEditLeadClosingPopup(false)}>
                Save
              </button>
              {/* <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setEditPopup(false)}>
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KnowledgeBaseSetMessage;
