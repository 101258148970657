import React from 'react';

const ButtonLoader = () => {
  return (
    <div className='button-loader '>
      <div className='button-spin'></div>
    </div>
  );
};

export default ButtonLoader;
