import React, { useEffect, useState } from 'react';
import { clearLocalStorage, getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../API/Api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const OpenAIConfiguration = () => {
  const [loader, setLoader] = useState(false);
  const [apiTokenData, setApiTokenData] = useState('');
  const [values, setValues] = useState({
    model_name: '',
    api_key: '',
  });
  const [errors, setErrors] = useState('');

  const Navigate = useNavigate();

  const { model_name, api_key } = values || {};
  console.log('values: ', values);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      fetchConfigurationDetails(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const fetchConfigurationDetails = (token) => {
    setLoader(true);
    getWithToken('openai_configurations', token)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.length > 0) {
          setValues(response?.data[0]);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleSaveClick = async () => {
    // if (!model_name || !api_key) {
    //   toast.error('Model name and API key cannot be blank');
    //   return;
    // }
    const payload = {
      model_name: model_name,
      api_key: api_key,
    };

    try {
      let response;
      if (values?.id) {
        response = await patchWithToken(`openai_configurations/${values.id}`, apiTokenData, payload);
      } else {
        response = await postWithToken('openai_configurations', apiTokenData, payload);
      }

      if (response.status === 200 || response.status === 201) {
        fetchConfigurationDetails(apiTokenData);
        toast.success(response.message);
      } else if (response.status === 500) {
        toast.error(response.message);
      } else if (response.status === 400) {
        toast.error(response.message);
      } else if (response.code === 'token_not_valid') {
        clearLocalStorage();
        toast.error('Token not valid. Please log in again.');
      } else {
        toast.error('Unexpected error occurred');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className=' chatbox-field bg-themeLightBlue no-height change-password-card'>
            <div className='card border-0 px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
              <div className='card-header border-0 p-0'>
                <div className='chatbox-input-text input-GPT new'>
                  <label className='opensans-medium'>Model Name</label>
                  <div className='chatbox-input-field'>
                    <select className='form-control box-shadow-hover' name='model_name' value={model_name} onChange={handleInputChange}>
                      <option selected hidden>
                        Choose the model
                      </option>
                      <option value='gpt-3.5-turbo-16k'>gpt-3.5-turbo-16k</option>
                      <option value='gpt-4o'>gpt-4o</option>
                      <option value='gpt-4o-mini'>gpt-4o-mini</option>
                    </select>
                  </div>
                </div>
                {/* <div className='chatbox-input-text input-GPT new'>
                  <label className='opensans-medium'>API Keys</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='text'
                      placeholder='https://chirpflo.com/'
                      className='form-control chatbox-input box-shadow-hover'
                      id='api_key'
                      name='api_key'
                      value={api_key}
                      autoComplete='off'
                      onChange={handleInputChange}
                    />
                  </div>
                </div> */}
                <div className=''>
                  <button className='btn btn-submit-login rounded-3' onClick={handleSaveClick}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
    </>
  );
};

export default OpenAIConfiguration;
