import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import '../assets/styles/Chatbot.css';
import '../assets/styles/KnowledgeBase.css';
import { FaBars, FaRegWindowClose } from 'react-icons/fa';

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className='row app-layout m-0'>
        <div className={`left-chirpflo-sidebar ${isOpen ? 'wrapperSidebar' : ''}`}>
          <Sidebar />
          <div className='toogle-icon set-toogle-icon' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaRegWindowClose /> : ''}
          </div>
        </div>
        <div className='right-chirpflo-sidebar p-0'>
          <div className='toogle-icon' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? '' : <FaBars />}
          </div>
          <div className='sideBarExtend d-flex' id='sidebar-wrapper'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
