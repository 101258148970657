import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BsArrowLeft } from 'react-icons/bs';
import ButtonLoader from '../../components/ButtonLoader';
import { toast } from 'react-toastify';

export default function CheckoutForm({ setSubscriptionTab, productsData }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          toast.success('Payment succeeded!');
          break;
        case 'processing':
          toast.info('Your payment is processing.');
          break;
        case 'requires_payment_method':
          toast.error('Your payment was not successful, please try again.');
          break;
        default:
          toast.error('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://chirpflo.com/subscription-thankyou',
        // return_url: 'http://192.168.1.109:3000/subscription-thankyou',
      },
    });
    setIsLoading(false);
    if (error.type === 'card_error' || error.type === 'validation_error') {
      toast.error(error.message);
    } else {
      toast.error('An unexpected error occurred.');
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <div className='row m-0 mt-5 mb-5 justify-content-between'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <BsArrowLeft className='font25 color-theme-darkLightblue cursor-pointer' onClick={() => setSubscriptionTab('1')} />
                <h4 className='font18 text-white opensans-bold m-0 ms-2 mt-2 mt-md-0'>Debit/Credit Card</h4>
              </div>
            </div>
            <div className='custom-hr my-4'></div>
            <div className='addons-container'>
              <p className='font18 color-theme-darkLightblue opensans-semibold mb-4'>Payment information</p>
              <PaymentElement id='payment-element' options={paymentElementOptions} />
              <div className='mt-4'>
                <button className='btn btn-submit-login w-100' disabled={isLoading || !stripe || !elements} type='submit' style={{ maxWidth: '100%' }}>
                  <div className='d-flex align-items-center justify-content-center'>
                    {isLoading && <ButtonLoader />}
                    <span className='ps-3'>PAY</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mt-md-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
              <h4 className='font18 text-white opensans-semibold m-0 mt-2 mt-md-0'>Summary</h4>
            </div>
            <div className='custom-hr my-4'></div>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
              <div className=''>
                <h4 className='font16 color-theme-darkLightblue opensans-regular m-0 mt-2 mt-md-0'>Subtotal</h4>
              </div>
              <div className='mt-2 mt-md-0'>
                <span className='font16 color-theme-darkLightblue opensans-regular m-0'>${productsData?.price}</span>
              </div>
            </div>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mt-2'>
              <div className=''>
                <h4 className='font16 color-theme-darkLightblue opensans-regular m-0 mt-2 mt-md-0'>VAT/Sales Tax 0%</h4>
              </div>
              <div className='mt-2 mt-md-0'>
                <span className='font16 color-theme-darkLightblue opensans-regular m-0'>$0.00</span>
              </div>
            </div>
            <div className='custom-hr my-4'></div>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mt-2'>
              <div className=''>
                <h4 className='font18 text-white opensans-semibold m-0 mt-2 mt-md-0'>Total Amount</h4>
              </div>
              <div className='mt-2 mt-md-0'>
                <span className='font18 text-white opensans-semibold m-0'>${productsData?.price}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
