import React from 'react';
import Header from '../components/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BELL_ICON from '../assets/icons/ChirpfloIcon/Bell-icon.svg';

const NavTabLayout = () => {
  const location = useLocation();

  return (
    <>
      <div className='intelichat-conatiner-right'>
        <div className='chatbot-container'>
          <div className='chatbot-fixed header-responsive pb-0'>
            <div className='d-block d-md-flex flex-wrap align-items-center justify-content-between'>
              <div className=''>
                <h3 className='opensans-bold mb-0'>
                  {location.pathname === '/chatbot'
                    ? 'Company Profile'
                    : location.pathname === '/chatbot/knowledgebase'
                    ? 'Bot Profile'
                    : location.pathname === '/chatbot/websitescrape'
                    ? 'Train Your Agent'
                    : location.pathname === '/chatbot/customizewidget'
                    ? 'Customize Widget'
                    : location.pathname === '/setting'
                    ? 'Profile'
                    : location.pathname === '/setting/changepassword'
                    ? 'Change Password'
                    : location.pathname === '/setting/openaiconfiguration'
                    ? 'OpenAi Configuration'
                    : 'Unknown Page'}
                </h3>
              </div>
              <div className='d-flex justify-content-end align-items-center responsive-header-width'>
                <div className='me-4'>
                  <img src={BELL_ICON} alt='bell-icon' className='cursor-pointer' />
                </div>
                <Header />
              </div>
            </div>
            <div className='bot-navtab mt-2'>
              {(location.pathname === '/chatbot' || location.pathname.includes('/chatbot')) && (
                <ul className='nav'>
                  <li className='nav-item'>
                    <Link className={`nav-link opensans-regualar ${location.pathname === '/chatbot/websitescrape' && 'active'}`} to='/chatbot/websitescrape'>
                      Knowledge Hub
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className={`nav-link opensans-regualar ${location.pathname === '/chatbot' && 'active'}`} aria-current='page' to='/chatbot'>
                      Company Profile
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className={`nav-link opensans-regualar ${location.pathname === '/chatbot/knowledgebase' && 'active'}`} to='/chatbot/knowledgebase'>
                      Bot Profile
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={`nav-link opensans-regualar ${location.pathname === '/chatbot/customizewidget' && 'active'}`}
                      to='/chatbot/customizewidget'
                      tabIndex='-1'
                      aria-disabled='true'
                    >
                      Customize Widget
                    </Link>
                  </li>
                </ul>
              )}
              {(location.pathname === '/setting' || location.pathname.includes('/setting')) && (
                <ul className='nav'>
                  <li className='nav-item'>
                    <Link className={`nav-link opensans-regualar ${location.pathname === '/setting' && 'active'}`} to='/setting' tabIndex='-1' aria-disabled='true'>
                      Profile
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={`nav-link opensans-regualar ${location.pathname === '/setting/openaiconfiguration' && 'active'}`}
                      to='/setting/openaiconfiguration'
                      tabIndex='-1'
                      aria-disabled='true'
                    >
                      OpenAi Configuration
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={`nav-link opensans-regualar ${location.pathname === '/setting/changepassword' && 'active'}`}
                      to='/setting/changepassword'
                      tabIndex='-1'
                      aria-disabled='true'
                    >
                      Change Password
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className=''>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavTabLayout;
