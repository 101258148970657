import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addClassToBody, clearLocalStorage, deleteAllWithToken, deleteWithToken, getLocalStorage, getWithToken, patchWithPdf, patchWithToken, postWithToken } from '../API/Api';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit, FaSave } from 'react-icons/fa';
import Loader from '../components/Loader';
import EditChatBox from '../components/EditChatBox';
import GifLoader from '../components/GifLoader';
import DELETE_ICON from '../assets/icons/ChirpfloIcon/Delete-icon.svg';
import FILE_PDF_ICON from '../assets/icons/ChirpfloIcon/File-pdf-icon.svg';
import UPLOAD_ICON from '../assets/icons/ChirpfloIcon/Upload-icon.svg';
import FAQ_ICON from '../assets/icons/ChirpfloIcon/Company-faq-icon.svg';
import ADDTEXTURL_ICON from '../assets/icons/ChirpfloIcon/Add-text-icon.svg';
import SCANURL_ICON from '../assets/icons/ChirpfloIcon/Scan-url-icon.svg';
import UPLOAD_ACTIVE_ICON from '../assets/icons/ChirpfloIcon/Upload-activeIcon.svg';
import FAQ_ACTIVE_ICON from '../assets/icons/ChirpfloIcon/Company-faq-activeIcon.svg';
import ADDTEXTURL_ACTIVE_ICON from '../assets/icons/ChirpfloIcon/Add-text-activeIcon.svg';
import REFRESH_ICON from '../assets/icons/ChirpfloIcon/Refresh-icon.svg';
import PLUS_ICON from '../assets/icons/ChirpfloIcon/Plus-icon.svg';
import SCANURL_ACTIVE_ICON from '../assets/icons/ChirpfloIcon/Scan-url-activeIcon.svg';
import moment from 'moment';
import { subContext } from '../App';

const WebsiteScrape = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const subsFlag = useContext(subContext);
  const [activeTab, setActiveTab] = useState('2');
  const [scan_url, setScan_url] = useState('');
  const [urlList, setUrlList] = useState([]);
  const [checkedUrlList, setCheckedUrlList] = useState([]);
  const [apiTokenData, setApiTokenData] = useState('');
  const [loader, setLoader] = useState(false);
  const [gifLoader, setGifLoader] = useState(false);
  const [selectedScanUrl, setSelectedScanUrl] = useState([]);
  const [displayContinue, setDisplayContinue] = useState(false);
  const [editData, setEditData] = useState([]);
  const [DeleteAllPopUpOpen, setDeleteAllPopUpOpen] = useState(false);
  const [DeletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [selectAllData, setSelectAllData] = useState(false);
  const [childData, setChildData] = useState(false);
  const [postDataPopUp, setPostDataPopUp] = useState(false);
  const [scaning, setScaning] = useState(false);
  const [addText, setAddText] = useState('');
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadedPdf, setUploadedPdf] = useState('');
  const [source, setSource] = useState('2');
  const [proccessData, setProccessData] = useState('');
  const [faqGenerationStatus, setFaqGenerationStatus] = useState(false);
  const [sendUrlLength, setSendUrlLength] = useState({
    Id: null,
    Length: '',
  });

  const [editPostValues, setEditPostValues] = useState({
    questions: '',
    answers: '',
  });

  const { questions, answers } = editPostValues;

  useEffect(() => {
    if (state !== null && state?.faqFlag) {
      setActiveTab('4');
      setSendUrlLength({
        Id: state?.id,
        Length: state?.length,
      });
    }
  }, []);
  useEffect(() => {
    addClassToBody('no-chat');
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      handleGetText(JSON.parse(token));
      knowledgeLibrary(JSON.parse(token));
      getUploadPdf(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, [childData, source]);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    let intervalId;

    const fetchData = () => {
      getWithToken('KB_questionsURLAPI/' + sendUrlLength?.Id, JSON.parse(token))
        .then((response) => {
          if (response.status === 200) {
            setProccessData(response?.data);
            knowledgeLibrary(JSON.parse(token));
            if (response.data.percentange === 100) {
              clearInterval(intervalId);
              setTimeout(() => {
                setSendUrlLength({
                  Id: null,
                  Length: '',
                });
                Navigate('/chatbot/websitescrape', { state: null });
                knowledgeLibrary(JSON.parse(token));
              }, 10000);
            }
          }
          if (response.status === 400) {
            toast.error(response?.message);
            clearInterval(intervalId);
            setSendUrlLength({
              Id: null,
              Length: '',
            });
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          toast.error('Something went wrong');
        });
    };

    if (sendUrlLength?.Id != null && token) {
      setTimeout(() => {
        fetchData();
        intervalId = setInterval(fetchData, 5000);
      }, 0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [sendUrlLength.Id]);

  const companyHandleChange = (e) => {
    setSelectedScanUrl([]);
    setScan_url(e.target.value);
  };

  const getUrlListData = (ScanUrl, token) => {
    setUrlList([]);
    if (ScanUrl != '') {
      let obj = {
        url: ScanUrl,
      };
      setScaning(true);
      postWithToken('Knowledge_baseURLAPI', token, obj)
        .then((response) => {
          let urlData = [];
          if (response.status === 200) {
            response?.data.forEach((element) => {
              urlData.push({
                data: element.url,
                checked: element.scan,
                disableChecked: element.scan,
              });
            });
            setScaning(false);
            setUrlList(urlData);
            setCheckedUrlList(urlData);
            setDisplayContinue(true);
            if (response?.message) {
              toast.success(response?.message);
              setFaqGenerationStatus(true);
            }
          } else if (response.code === 'token_not_valid') {
            clearLocalStorage();
            setScaning(false);
          } else {
            toast.error(response?.message);
            setScaning(false);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong');
        });
    } else {
      toast.error('Enter website URL');
    }
  };

  const onChangeofScan = (e, index) => {
    if (e.target.checked === true) {
      setSelectedScanUrl([...selectedScanUrl, { value: e.target.value, index: index }]);
      let newUrlData = [...urlList];
      newUrlData = newUrlData.filter((value, ind) => {
        if (index === ind) {
          newUrlData.splice(ind, 1, { data: value.data, checked: true });
          setUrlList(newUrlData);
        }
      });
    } else {
      let newArray = [...selectedScanUrl];
      newArray = newArray.filter((value, i) => value.index !== index);
      setSelectedScanUrl(newArray);
      let newUrlData = [...urlList];
      newUrlData = newUrlData.filter((value, ind) => {
        if (index === ind) {
          newUrlData.splice(ind, 1, { data: value.data, checked: false });
          setUrlList(newUrlData);
        }
      });
    }
  };

  const handleSelectAllData = () => {
    setSelectAllData(true);
    let setUrlData = [];
    let selectedData = [];
    urlList.forEach((element, index) => {
      selectedData.push({ value: element.data, index: index });
      setUrlData.push({
        data: element.data,
        checked: true,
        disableChecked: element.disableChecked,
      });
    });
    setSelectedScanUrl(selectedData);
    setUrlList(setUrlData);
  };
  const handleDeSelectAllData = () => {
    setSelectAllData(false);
    let setUrlData = [];
    urlList.forEach((element, index) => {
      setUrlData.push({
        data: element.data,
        checked: false,
        disableChecked: element.disableChecked,
      });
    });
    setSelectedScanUrl([]);
    setUrlList(checkedUrlList);
  };

  const submitScanData = () => {
    if (selectedScanUrl.length > 0) {
      setGifLoader(true);
      setEditData([]);
      let sendUrlData = [];
      selectedScanUrl.forEach((data) => {
        sendUrlData.push(data.value);
      });
      let obj = {
        url_links: sendUrlData,
      };
      postWithToken('KB_questionsURLAPI', apiTokenData, obj)
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setGifLoader(false);
              setSendUrlLength({
                Id: response?.task_id,
                Length: sendUrlData?.length,
              });
              if (faqGenerationStatus) {
                Navigate('/chatbot', { state: { faqFlag: true, id: response?.task_id, length: sendUrlData?.length } });
              } else {
                setActiveTab('4');
              }
            }, 8000);
            knowledgeLibrary(apiTokenData);
          } else if (response.code === 'token_not_valid') {
            clearLocalStorage();
          } else if (response.status === 504) {
            toast.error('Extracting knowledge base in the background, you may proceed.');
          } else {
            toast.dismiss();
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong');
        });
    } else {
      toast.error('Select minimum 1 URL');
    }
  };

  const knowledgeLibrary = (token) => {
    setLoader(true);
    postWithToken('Questions_API/', token, { source: source })
      .then((response) => {
        if (response.status === 200) {
          let editDataValue = [];
          response.data.forEach((element, index) => {
            editDataValue.push({
              value: false,
              index: index,
              question: element.questions,
              answer: element.answers,
              id: element.id,
              source: element.source,
              source_name: element.source_name,
            });
          });
          setEditData(editDataValue);
          setChildData(false);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          setLoader(false);
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error('Something went wrong');
      });
  };

  const handelEditData = (newData) => {
    let handlerEdit = [...editData];
    handlerEdit[newData.index].value = true;
    setEditData(handlerEdit);
  };

  const handelSaveData = (newData) => {
    let handlerEdit = [...editData];
    handlerEdit[newData.index].value = false;
    setEditData(handlerEdit);

    let obj = {
      source: newData.source,
      questions: newData.question,
      answers: newData.answer,
    };

    patchWithToken('Questions_API/' + newData.id, apiTokenData, obj)
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss();
          toast.success('Message successfully updated.');
          knowledgeLibrary(apiTokenData);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleDelete = (newData) => {
    setDeleteData(newData);
    setDeletePopUpOpen(true);
  };
  const handleDeleteData = () => {
    deleteWithToken('Questions_API/' + deleteData.id, apiTokenData)
      .then((response) => {
        if (response.status === 200) {
          knowledgeLibrary(apiTokenData);
          toast.success('Message Deleted');
          setLoader(false);
          setDeletePopUpOpen(false);
        } else if (response.code === 'token_not_valid') {
          knowledgeLibrary(apiTokenData);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };
  const handleDeleteAllData = () => {
    deleteAllWithToken('Questions_ALL_API/', apiTokenData, { source: source })
      .then((response) => {
        if (response.status === 200) {
          knowledgeLibrary(apiTokenData);
          toast.success("All FAQ's cleared successfully.");
          setLoader(false);
          setDeleteAllPopUpOpen(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const editQuestion = (event, newData, key) => {
    if (key === 'question') {
      let handlerEdit = [...editData];
      handlerEdit[newData.index].value = true;
      handlerEdit[newData.index].question = event.target.value;
      setEditData(handlerEdit);
    } else {
      let handlerEdit = [...editData];
      handlerEdit[newData.index].value = true;
      handlerEdit[newData.index].answer = event.target.value;
      setEditData(handlerEdit);
    }
  };

  const onChangeEditNewData = (e) => {
    setEditPostValues({
      ...editPostValues,
      [e.target.name]: e.target.value,
    });
  };

  const savePostData = () => {
    setLoader(true);
    const obj = {
      questions: editPostValues.questions,
      answers: editPostValues.answers,
    };
    postWithToken('Questions_ALL_API/', apiTokenData, obj)
      .then((response) => {
        if (response.status === 200) {
          knowledgeLibrary(apiTokenData);
          setLoader(false);
          setPostDataPopUp(false);
          setEditPostValues({
            questions: '',
            answers: '',
          });
          toast.success(response.message);
        } else if (response.status === 400) {
          toast.error(response.message);
          setPostDataPopUp(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
      });
  };

  const handleGetText = (token) => {
    getWithToken('Text_questionsURLAPI', token)
      .then((response) => {
        if (response.status === 200) {
          setAddText(response?.data);
        } else if (response.status === 400) {
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // const countWords = (text) => {
  //   return text.trim().split(/\s+/).length;
  // };
  const handleAddText = () => {
    // const totalWordCount = (subsFlag?.conversionData?.word_count || 0) + countWords(addText);
    // console.log('totalWordCount: ', totalWordCount);

    // const MAX_WORD_COUNT = 20000;

    // if (totalWordCount > MAX_WORD_COUNT) {
    //   toast.error('Total word count exceeds the 20,000-word limit.');
    //   return;
    // }
    const obj = {
      text: addText,
    };
    patchWithToken('Text_questionsURLAPI', apiTokenData, obj)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          subsFlag.setUpdateValue('1');
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDrop = useCallback(async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const FileData = [file];
    setUploadFile((prevSelectedFiles) => [...prevSelectedFiles, ...FileData]);

    await patchUploadPdf(file, apiTokenData);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSelectFile = async (e) => {
    const file = e.target.files[0];
    await patchUploadPdf(file, apiTokenData);
  };

  const patchUploadPdf = async (file, token) => {
    if (file) {
      setLoader(true);
      const MAX_FILE_SIZE = 15 * 1024 * 1024;

      if (file.size > MAX_FILE_SIZE) {
        toast.error('Please upload files no larger than 15MB!');
        return setLoader(false);
      }

      const allowedExtensions = ['pdf'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        toast.error('Unsupported file format. Please use PDF files.');
        return setLoader(false);
      }
      let fd = new FormData();
      fd.append('pdf', file);

      try {
        const response = await patchWithPdf('PDF_questionsURLAPI', token, fd);
        if (response.status === 200) {
          toast.success(response.message);
          getUploadPdf(apiTokenData);
          setLoader(false);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    }
  };

  const getUploadPdf = (token) => {
    getWithToken('PDF_questionsURLAPI', token)
      .then((response) => {
        if (response.status === 200) {
          setUploadedPdf(response?.data);
          subsFlag.setUpdateValue('1');
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {});
  };

  const handleUploadedDelete = async () => {
    try {
      const response = await deleteWithToken('PDF_questionsURLAPI', apiTokenData);
      if (response.status === 200) {
        getUploadPdf(apiTokenData);
        toast.success(response.message);
      } else if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const countCharacters = (text) => {
    if (text) {
      const trimmedText = text.trim();
      return trimmedText.length;
    }
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-3 pe-sm-3 pe-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='custom-tab'>
              <button
                className={`btn custom-tab-button opensans-bold ${activeTab === '2' ? 'bg-theme-cylindricalBlue text-white' : ''}`}
                onClick={() => setActiveTab('2')}
                onKeyDown={handleKeyDown}
              >
                <span>
                  <img src={`${activeTab === '2' ? SCANURL_ACTIVE_ICON : SCANURL_ICON}`} className='me-2' alt='scanurl-icon' />
                  Scan URL
                </span>
              </button>
              <button
                className={`btn custom-tab-button opensans-bold ${activeTab === '1' ? 'bg-theme-cylindricalBlue text-white' : ''}`}
                onClick={() => setActiveTab('1')}
                onKeyDown={handleKeyDown}
              >
                <span>
                  <img src={`${activeTab === '1' ? UPLOAD_ACTIVE_ICON : UPLOAD_ICON}`} className='me-2' alt='upload-icon' />
                  Upload a file
                </span>
              </button>

              <button
                className={`btn custom-tab-button opensans-bold ${activeTab === '3' ? 'bg-theme-cylindricalBlue text-white' : ''}`}
                onClick={() => setActiveTab('3')}
                onKeyDown={handleKeyDown}
              >
                <span>
                  <img src={`${activeTab === '3' ? ADDTEXTURL_ACTIVE_ICON : ADDTEXTURL_ICON}`} className='me-2' alt='text-icon' />
                  Add Text
                </span>
              </button>
              <button
                className={`btn custom-tab-button opensans-bold ${activeTab === '4' ? 'bg-theme-cylindricalBlue text-white' : ''}`}
                onClick={() => setActiveTab('4')}
                onKeyDown={handleKeyDown}
              >
                <span>
                  <img src={`${activeTab === '4' ? FAQ_ACTIVE_ICON : FAQ_ICON}`} className='me-2' alt='faq-icon' />
                  Company FAQ
                </span>
              </button>
            </div>

            {(activeTab === '1' || activeTab === '2') && (
              <div className='mb-5 mt-5'>
                <h4 className='text-white opensans-bold'>Give It a Brain</h4>
                <p className='mb-0 opensans-regular color-theme-darkLightblue font18'>
                  This is where your agent learns everything it needs to know. Upload your PDF docs and enter your website url to discover how powerful Chirpflo can be and see how
                  it generates FAQs and answers with mind blowing accuracy.
                </p>
              </div>
            )}
            {activeTab === '1' && (
              <div onDrop={handleDrop} onDragOver={handleDragOver} className='custom-drag-drop'>
                <div className='custom-drag-drop-body text-center'>
                  <img src={UPLOAD_ICON} alt='upload-icon' className='mb-3' />
                  <h6 className='opensans-bold'>Click to upload a file or drag and drop it here</h6>
                  <p className='opensans-regular my-3'>
                    PDF MAX 15mb <span className=''>*</span>
                  </p>
                  <label className='custom-input-label rounded m-auto'>
                    <input type='file' onChange={(e) => handleSelectFile(e)} className='custom-input' />
                    Upload File
                  </label>
                </div>
              </div>
            )}
            {activeTab === '2' && (
              <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-7 col-sm-12 col-12 website-scrape-text m-auto'>
                <div className='websitescrape-input-field'>
                  <input
                    type='text'
                    placeholder='Enter a Website Url to scan'
                    className='form-control chatbox-input mx-auto box-shadow-hover'
                    autoComplete='off'
                    value={scan_url}
                    onChange={companyHandleChange}
                    disabled={displayContinue}
                  />
                </div>
                <div className='mt-5'>
                  {!displayContinue && (
                    <button
                      className={`btn btn-submit-login mx-auto ${scaning && 'dot-loading d-flex justify-content-center align-items-center'} `}
                      onClick={() => getUrlListData(scan_url, apiTokenData)}
                    >
                      {scaning ? 'Scanning' : 'Scan My Website'}
                    </button>
                  )}
                  {/* ) : (
                  <button className='btn btn-submit-login mx-auto' onClick={() => submitScanData()}>
                     Continue 
                  </button>
                 )} */}
                </div>
              </div>
            )}
            {activeTab === '3' && (
              <>
                <div className='mt-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='mb-1 opensans-bold text-white'>Add Text</label>
                    <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-end'>({countCharacters(addText) || 0} characters)</p>
                  </div>
                  <textarea
                    placeholder='Add Your Text'
                    className='addtextbox-textarea px-3 py-2 box-shadow-hover'
                    value={addText}
                    rows={15}
                    onChange={(e) => setAddText(e.target.value)}
                  />
                </div>
                <button className='btn btn-submit-login mx-auto mt-5' onClick={() => handleAddText()}>
                  Train Your Bot
                </button>
              </>
            )}
            {activeTab === '4' && (
              <div className='mt-5'>
                <h4 className='opensans-semiBold text-white mb-4'>FAQs Created By</h4>

                <div className='custom-tab m-0 justify-content-between width550'>
                  <button
                    className={`btn custom-tab-button minWidth160 opensans-bold ${source === '2' ? 'bg-white color-theme-DarkPurple' : ''}`}
                    onClick={() => {
                      setSource('2');
                      setPostDataPopUp(false);
                    }}
                    onKeyDown={handleKeyDown}
                  >
                    Chirpflo
                  </button>
                  <button
                    className={`btn custom-tab-button minWidth160 opensans-bold ${source === '1' ? 'bg-white color-theme-DarkPurple' : ''}`}
                    onClick={() => {
                      setSource('1');
                      setPostDataPopUp(false);
                    }}
                    onKeyDown={handleKeyDown}
                  >
                    Yourself
                  </button>
                  <button
                    className={`btn custom-tab-button minWidth160 opensans-bold ${source === '3' ? 'bg-white color-theme-DarkPurple' : ''}`}
                    onClick={() => {
                      setSource('3');
                      setPostDataPopUp(false);
                    }}
                    onKeyDown={handleKeyDown}
                  >
                    Live Edited Response
                  </button>
                </div>

                <div className='d-flex flex-wrap align-items-center justify-content-between pt-4 mb-3'>
                  <div className='d-flex align-items-center justify-content-between'>
                    {sendUrlLength?.Id != null && source === '2' && (
                      <div className='d-flex align-items-center'>
                        <div className='opensans-bold text-white'>Success URL : {proccessData?.success_url != undefined ? proccessData?.success_url : 0} </div>

                        <div className='opensans-bold text-white mx-3'>Total URL : {sendUrlLength.Length > 9 ? sendUrlLength.Length : `0${sendUrlLength.Length}`}</div>

                        <div className='progress me-3' style={{ width: '150px', height: '20px' }}>
                          <div
                            className='progress-bar progress-bar-striped bg-themeLightPurple'
                            role='progressbar'
                            style={{ width: `${proccessData?.percentange}%`, background: '#04bcff' }}
                            aria-valuenow='10'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          >
                            {proccessData?.percentange ? proccessData?.percentange : '0'}%
                          </div>
                        </div>
                      </div>
                    )}

                    <button
                      className='btn btn-submit-add mt-md-0 mt-2'
                      onClick={() => {
                        setActiveTab('2');
                      }}
                    >
                      <img src={REFRESH_ICON} alt='refresh-icon' className='me-2' />
                      Continue Scanning URL
                    </button>
                  </div>
                  {source == '1' && (
                    <div className=''>
                      <button className='btn btn-submit-add mt-sm-0 mt-2' onClick={() => setPostDataPopUp(true)}>
                        <img src={PLUS_ICON} alt='plus-icon' className='me-2' />
                        Add New FAQs
                      </button>
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap align-items-center m-0'>
                  <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 p-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                      {editData.length !== 0 && (
                        <>
                          <span className='opensans-regular color-theme-darkLightblue'>
                            Learned Pairs:<span className='mx-1'>{editData.length > 9 ? editData.length : `0${editData.length}`}</span>
                          </span>
                          <button className='btn border-0 color-theme-lightPurple opensans-semiBold' onClick={() => setDeleteAllPopUpOpen(true)}>
                            Clear All FAQs
                          </button>
                        </>
                      )}
                    </div>
                    {postDataPopUp && (
                      <div className='mt-3 knowledge-pair-body'>
                        <textarea
                          placeholder='Enter your question here.'
                          id='questions'
                          name='questions'
                          values={questions}
                          onChange={(e) => onChangeEditNewData(e)}
                          autoComplete='off'
                          className='px-3 py-2'
                        ></textarea>
                        <hr className='text-white m-0' />
                        <textarea
                          placeholder='Enter your answer here.'
                          id='answers'
                          name='answers'
                          values={answers}
                          onChange={(e) => onChangeEditNewData(e)}
                          autoComplete='off'
                          className='px-3 py-2'
                        ></textarea>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 p-0'>
                    {postDataPopUp && (
                      <div className='text-white knowledge-pair-icon text-left text-md-center'>
                        {editPostValues.questions != '' && editPostValues.answers != '' && (
                          <button className='btn' type='button' onClick={() => savePostData()}>
                            <FaSave />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='card-body knowledge-pair-overflow'>
                  <div className='knowledge-pair-container'>
                    {editData?.map((data, index) => (
                      <div className='row align-items-center m-0' key={index}>
                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 p-0'>
                          <div className='mt-3 knowledge-pair-body'>
                            {!editData[index].value ? (
                              <p className='opensans-regular text-white px-3 py-2 m-0'>{data.question}</p>
                            ) : (
                              <textarea placeholder='Add question' className='px-3 py-2' onChange={(e) => editQuestion(e, data, 'question')}>
                                {data.question}
                              </textarea>
                            )}
                            <hr className='text-white m-0' />
                            {!editData[index].value ? (
                              <p className='opensans-regular color-theme-darkLightblue px-3 py-2 m-0'>{data.answer}</p>
                            ) : (
                              <textarea placeholder='Add answer' className='px-3 py-2' onChange={(e) => editQuestion(e, data, 'answer')}>
                                {data.answer}
                              </textarea>
                            )}
                          </div>
                          {data.source_name != '' && (
                            <p className='opensans-regular color-theme-darkLightblue opacity-75 px-3 py-2 m-0'>
                              <span className='opensans-bold'>Source Name :</span> {data.source_name}
                            </p>
                          )}
                        </div>

                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 p-0'>
                          <div className='text-white knowledge-pair-icon text-left text-md-center'>
                            {!editData[index].value ? (
                              <button className='btn' type='button' onClick={() => handelEditData(data)}>
                                <FaEdit />
                              </button>
                            ) : (
                              <button className='btn' type='button' onClick={() => handelSaveData(data)}>
                                <FaSave />
                              </button>
                            )}
                            <button className='btn' type='button' onClick={() => handleDelete(data)}>
                              <AiFillDelete />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {activeTab === '1' && (
            <div className='tablerounededCorner overflowX-scroll mt-5 border-0'>
              {/* <div className='table-rounded-header'>
                <h4 className='mb-0'>Uploaded files</h4>
              </div> */}
              <table className='table table-striped roundedTable rounded-0 mb-0'>
                <thead>
                  <tr className='bg-themePurple border-bottom-0'>
                    <td colSpan={6} className='p-0'>
                      <div className='table-rounded-header'>
                        <h4 className='mb-0'>Uploaded files</h4>
                      </div>
                    </td>
                  </tr>
                  <tr className='bg-themeDarkPurple'>
                    <th className='opensans-bold'>Title</th>
                    <th className='opensans-bold text-center'>Words Amount</th>
                    <th className='opensans-bold text-center'>Uploaded at</th>
                    <th className='opensans-bold text-center'>Data type</th>
                    <th className='opensans-bold text-center'>Status</th>
                    <th className='opensans-bold text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedPdf?.pdf_url?.length > 0 || uploadFile.length > 0 ? (
                    <>
                      {uploadedPdf?.pdf_url && (
                        <tr className='cursor-pointer'>
                          <td>
                            <a href={uploadedPdf.pdf_url} target='_blank' rel='noopener noreferrer' className='m-0 opensans-bold'>
                              {`${uploadedPdf.name.length > 20 ? uploadedPdf.name.substring(0, 20) + '...' : uploadedPdf.name} `}
                            </a>
                          </td>
                          <td className='opensans-regular text-center color-theme-darkLightblue'>{uploadedPdf?.word_count}</td>
                          <td className='opensans-regular text-center color-theme-darkLightblue'>{moment(uploadedPdf?.upload_at).format('DD-MM-YYYY')}</td>
                          <td className='opensans-regular text-center'>
                            <img src={FILE_PDF_ICON} alt='pdf-icon' />
                            {/* <FaFilePdf /> */}
                          </td>
                          <td className='opensans-regular text-center'>
                            <button className='btn bg-themeLightGreen text-white opensans-regular rounded-pill'>Trained</button>
                          </td>
                          <td className='opensans-regular text-center'>
                            <img src={DELETE_ICON} alt='delete-icon' onClick={handleUploadedDelete} />
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <p className='text-center text-white py-3' style={{ fontSize: '20px' }}>
                          No Records Found
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {activeTab == '2' && urlList.length > 0 && (
            <div className='bg-themeLightBlue mt-4'>
              <div className='website-scrape-body mb-5'>
                {displayContinue && (
                  <div className=''>
                    {!selectAllData ? (
                      <button className='btn btn-submit-add me-2' onClick={() => handleSelectAllData()}>
                        Select All
                      </button>
                    ) : (
                      <button className='btn btn-submit-add me-2 ' onClick={() => handleDeSelectAllData()}>
                        Deselect All
                      </button>
                    )}
                  </div>
                )}
                {urlList.map((row, i) => (
                  <>
                    <div className='d-flex align-items-center mt-4 websitescrape-label'>
                      <label style={{ opacity: row?.disableChecked ? '0.5' : '1' }} className={row?.disableChecked ? 'scrapeSelected' : 'noScrapeSelected'}>
                        <input type='checkbox' id='i' name='i' disabled={row?.disableChecked} onChange={(e) => onChangeofScan(e, i)} value={row.data} checked={row?.checked} />
                        {row?.data}
                      </label>
                    </div>
                  </>
                ))}
              </div>
              <button className='btn btn-submit-login mx-auto' onClick={() => submitScanData()}>
                Train My Agents
              </button>
            </div>
          )}
        </div>
        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 px-sm-3 px-0'>
          <EditChatBox />
        </div>
      </div>

      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
      {gifLoader && (
        <div className='custom-popup'>
          {/* <GifLoader /> */}
          <div className='popup-content' style={{ maxWidth: '450px' }}>
            <div className={'popup-head my-4'}>
              <h3 className='opensans-semibold text-white text-center'>Chirpflo Learning</h3>
              <p className='opensans-regular text-white loader-text pt-md-3 pt-2'>
                Chirpflo is on a quest to learn everything about your business, like a brave explorer in uncharted territory. So please sit tight as it maps out the perfect set of
                FAQs for you. In a moment, you'll see how amazing it is as it creates a set of answers based on what it's learning right now. And if it times out, just hit retry
                and we'll continue from where we left off!
              </p>
            </div>
          </div>
        </div>
      )}

      {DeleteAllPopUpOpen && (
        <div className='custom-popup'>
          <div className='popup-content'>
            {/* <hr /> */}
            <div className={'popup-head my-4'}>
              <h4>Are you sure you want to delete all data?</h4>
            </div>
            {/* <hr /> */}
            <div className='popup-footer'>
              <button className='btn bg-danger text-white mx-1 text-capitalize' onClick={() => handleDeleteAllData()}>
                Delete
              </button>
              <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setDeleteAllPopUpOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {DeletePopUpOpen && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <div className={'popup-head mt-3 mb-4'}>
              <h4 className='mb-0'>Are you sure you want to delete?</h4>
            </div>
            <div className='popup-footer'>
              <button className='btn bg-danger text-white mx-1 text-capitalize' onClick={() => handleDeleteData()}>
                Delete
              </button>
              <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setDeletePopUpOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebsiteScrape;
