import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ContactChart = (props) => {
  const state = {
    series: [
      {
        name: 'Contact',
        type: 'area',
        data: [0, 50, 10, 35, 20, 60, 17, 25, 15, 31, 15, 60] || props?.data?.data,
      },
    ],

    options: {
      chart: {
        height: 320,
        type: 'area',
        toolbar: {
          show: false,
          autoSelected: false,
          tools: {
            download: false,
          },
        },
      },
      stroke: {
        curve: ['straight'],
        width: 2,
        colors: ['#0DEFF6'],
      },
      markers: {
        show: true,
        size: 5,
        colors: '#0DEFF6',
        strokeColors: '#0DEFF6',
        strokeWidth: 2,
        shape: 'circle',
        radius: 2,
        hover: {
          size: 8,
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: '20px',
          colors: ['#ffffff'],
        },
      },
      fill: {
        type: 'gradient',
        colors: ['#013a3cc7', '#0DEFF6'],
        gradient: {
          opacity: 0.2,
          gradientToColors: ['#0DEFF6', '#013a3cc7'],
          inverseColors: true,
          shadeIntensity: 1,
          type: 'vertical',
          stops: [0, 90, 100],
        },
      },

      xaxis: {
        categories: props?.data?.category,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#04BCFF',
              colorTo: '#FFFFFF',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        labels: {
          style: {
            colors: '#ffffff',
            fontSize: '14px',
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      grid: {
        show: true,
        borderColor: '#5356fb87',
        strokeDashArray: 5,

        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <div className='react-chart'>
      <ReactApexChart options={state.options} series={state.series} type='area' height={320} />
    </div>
  );
};

export default ContactChart;
