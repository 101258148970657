import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { clearLocalStorage, deleteWithToken, getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../API/Api';
import { toast } from 'react-toastify';
import CHATBOX_LOGO from '../assets/icons/ChirpfloIcon-V2.0/chatbot-icon.svg';
import { FiSearch } from 'react-icons/fi';
import Linkify from 'react-linkify';
import Loader from '../components/Loader';
import 'react-calendar/dist/Calendar.css';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import DELETE_ICON from '../assets/icons/ChirpfloIcon/Delete-icon.svg';
import DOWN_ARROW_ICON from '../assets/icons/ChirpfloIcon/PurpleDown-arrow-icon.svg';
import UP_ARROW_ICON from '../assets/icons/ChirpfloIcon/PurpleUp-arrow-icon.svg';
import CALENDAR_ICON from '../assets/icons/ChirpfloIcon/Calendar-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEdit, FaPlus, FaSave } from 'react-icons/fa';
import { MdOutlineDeleteForever, MdOutlineModeEditOutline } from 'react-icons/md';

const MyLeads = () => {
  const [leadData, setLeadData] = useState([]);
  const [leadStatus, setLeadSatus] = useState([]);
  const [apiTokenData, setApiTokenData] = useState('');
  const [getLeadData, setGetLeadData] = useState([]);
  const [leadId, setLeadId] = useState('');
  const [chatData, setChatData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterStatus, setFlterStatus] = useState(null);
  // const [activeStatus, setActiveStatus] = useState(null);
  const dateRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [editedSummary, setEditedSummary] = useState({});
  const [isEditing, setIsEditing] = useState({});

  const [showPopup, setShowPopup] = useState(false);
  const [editLabel, setEditLabel] = useState(null);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      getLeadDetails(JSON.parse(token));
      setApiTokenData(JSON.parse(token));
      getstatus(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    const filtered = leadData.filter(
      (obj) =>
        obj?.name?.toLowerCase().includes(search.toLowerCase()) ||
        obj?.email?.toLowerCase().includes(search.toLowerCase()) ||
        obj?.phone_number?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  }, [search, leadData]);

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      handleDateChange(JSON.parse(token));
    }
  }, [filterStatus, startDate]);

  const getLeadDetails = (token) => {
    setLoader(true);
    getWithToken('Lead_ListAPI', token)
      .then((response) => {
        if (response.status === 200) {
          setLeadData(response?.data);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error('Something went wrong');
      });
  };

  const handleSummaryChange = (e, leadId) => {
    setEditedSummary((prev) => ({ ...prev, [leadId]: e.target.value }));
  };
  const handleEditClick = (leadId, initialSummary) => {
    setIsEditing((prev) => ({ ...prev, [leadId]: true }));
    setEditedSummary((prev) => ({ ...prev, [leadId]: initialSummary }));
  };

  const handleSaveClick = (leadId) => {
    setIsEditing((prev) => ({ ...prev, [leadId]: false }));
    const payload = {
      lead_summary: editedSummary[leadId],
    };

    patchWithToken(`Lead_ListAPI/${leadId}`, apiTokenData, payload)
      .then((response) => {
        if (response.status === 200) {
          getLeadDetails(apiTokenData);
          toast.success('Summary successfully updated');
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleLeadsDetails = (obj) => {
    setLeadId(obj?.id);
    const payload = {
      recipient_id: obj?.recipient_id,
    };
    postWithToken('Lead_ListAPI', apiTokenData, payload)
      .then((response) => {
        if (response.status === 200) {
          setGetLeadData(response?.data);
          setChatData(true);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleToggleChatHistory = (obj) => {
    setLeadId((prevLeadId) => (obj.id === prevLeadId ? null : obj.id));
    if (obj?.id === leadId) {
      setChatData(false);
    } else {
      handleLeadsDetails(obj);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (token) => {
    const payload = {
      date: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      status: filterStatus,
    };
    postWithToken('lead_SortingAPI', token, payload)
      .then((response) => {
        if (response.status === 200) {
          setLeadData(response?.data);
          setGetLeadData([]);
          setChatData(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleStatusChange = (e, id, activeID) => {
    const newStatus = activeID;
    const updatedData = filteredData.map((lead) => {
      if (lead.id === id) {
        return { ...lead, status: newStatus, activeID: activeID };
      }
      return lead;
    });

    setFilteredData(updatedData);
    patchWithToken(`Lead_ListAPI/${id}`, apiTokenData, {
      status: newStatus,
    })
      .then((response) => {
        if (response.status === 200) {
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };
  const formattedDates = filteredData.map((obj) => {
    const currentDate = new Date();
    const createdAtDate = new Date(obj?.createdAt);

    let formattedDate;

    if (isSameDay(currentDate, createdAtDate)) {
      formattedDate = 'Today';
    } else if (isYesterday(currentDate, createdAtDate)) {
      formattedDate = 'Yesterday';
    } else {
      formattedDate = moment(createdAtDate).format('DD-MM-YYYY');
    }

    function isSameDay(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    function isYesterday(date1, date2) {
      const yesterday = new Date(date1);
      yesterday.setDate(date1.getDate() - 1);

      return isSameDay(yesterday, date2);
    }

    return formattedDate;
  });

  const handleDelateLead = (id) => {
    setLoader(true);
    deleteWithToken(`Lead_ListAPI/${id}`, apiTokenData)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          getLeadDetails(apiTokenData);
          toast.success(response?.message);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error('Something went wrong');
      });
  };

  const getstatus = (token) => {
    setLoader(true);
    getWithToken('lead_status', token)
      .then((response) => {
        if (response.status === 200) {
          setLeadSatus(response?.data);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error('Something went wrong');
      });
  };

  const handleOpenPopup = (label) => {
    setShowPopup(true);
    setEditLabel(label);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setEditLabel(null);
  };

  const handleSaveLabel = async (id, name) => {
    console.log('id: ', id);
    try {
      if (id) {
        const response = await patchWithToken(`lead_status/${id}`, apiTokenData, { status_name: name });
        if (response.status === 200) {
          toast.success(response.message);
          getstatus(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      } else {
        const response = await postWithToken('lead_status', apiTokenData, { status_name: name });
        if (response.status === 201) {
          toast.success(response.message);
          getstatus(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteStatus = async (id) => {
    try {
      const response = await deleteWithToken(`lead_status/${id}`, apiTokenData);
      if (response.status === 200) {
        toast.success(response.message);
        getstatus(apiTokenData);
        getLeadDetails(apiTokenData);
      } else if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const LabelPopup = ({ onClose, onSave, labelData }) => {
    const [name, setName] = useState(labelData?.status_name || '');

    const handleSave = () => {
      onSave(labelData?.id, name);
      onClose();
    };

    const handleCancel = () => {
      onClose();
    };

    const handleInputChange = (event) => {
      setName(event.target.value);
    };

    return (
      <>
        <div className='new-label-popup'>
          <div className='popup-content'>
            <input type='text' placeholder='Enter status name' value={name} onChange={handleInputChange} />
            <div className='button-container'>
              <button className='btn btn-primary' onClick={handleSave}>
                Save
              </button>
              <button className='btn btn-secondary' onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className='popup-overlay' onClick={handleCancel} />
      </>
    );
  };

  return (
    <>
      <div className='intelichat-conatiner-right'>
        <div className='chatbot-container'>
          <div className='chatbot-fixed header-responsive'>
            <div className='d-block d-sm-flex flex-wrap align-items-center justify-content-between'>
              <h3 className='opensans-bold mb-0'>My Leads</h3>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='search-lead input-group'>
                  <span className='input-group-text' id='basic-addon1'>
                    <FiSearch />
                  </span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search here'
                    aria-label='Username'
                    aria-describedby='basic-addon1'
                    value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <div className='d-flex justify-content-end responsive-header-width'>
                  <Header />
                </div>
              </div>
            </div>
          </div>
          <div class='d-flex align-items-center flex-wrap m-0 mt-5 px-md-5 px-sm-3 px-3 mb-5'>
            <div class='me-3 position-relative custom-datepicker'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText='Start date'
                class='m-0 box-shadow-hover width150 form-control'
                ref={dateRef}
                maxDate={new Date()}
              />
              <img src={CALENDAR_ICON} alt='Calendar-icon' class='calender-icon' onClick={() => dateRef.current.setFocus()} />
            </div>
            <div class='chatbox-field'>
              <div class='dropdown select-dropdown' id='custom-select'>
                <button class='btn btn-secondary custom-dropdown-toggle box-shadow-hover' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                  {leadStatus.map((label, index) => (
                    <span key={index}>{label.id === filterStatus && label.status_name}</span>
                  ))}
                  <span>{!filterStatus && 'Select'}</span>
                </button>
                <ul class='dropdown-menu p-2' id='dropdown-menu'>
                  {leadStatus.map((label, index) => (
                    <li class='dropdown-item' key={index}>
                      <div class='dropdown-item-list'>
                        <button class='btn text-white' onClick={() => setFlterStatus(label?.id)}>
                          <span class=''>{label.status_name}</span>
                        </button>
                      </div>
                      <div class='dropdown-item-icons'>
                        <button class='btn p-0' onClick={() => handleOpenPopup(label)}>
                          <MdOutlineModeEditOutline />
                        </button>
                        <button class='btn p-0' onClick={() => handleDeleteStatus(label?.id)}>
                          <MdOutlineDeleteForever />
                        </button>
                      </div>
                    </li>
                  ))}
                  <li class='dropdown-item new-label mt-2' onClick={() => handleOpenPopup(null)}>
                    <FaPlus />
                    <span class='ps-2'>Create a new status</span>
                  </li>
                </ul>
                {showPopup && <LabelPopup onClose={handleClosePopup} onSave={handleSaveLabel} labelData={editLabel} />}
              </div>
            </div>
            <div class='ms-3'>
              <span
                class='mb-0 opensans-regular color-theme-darkLightblue font14 cursor-pointer'
                onClick={() => {
                  setFlterStatus(null);
                  setStartDate(null);
                }}
              >
                Clear all
              </span>
            </div>
          </div>

          {filteredData?.length > 0 ? (
            filteredData?.map((obj, index) => {
              return (
                <div className='row m-0 mt-5 px-md-5 px-sm-3 px-3 mb-5'>
                  <div className='chatbox-field bg-themeLightBlue'>
                    <div className='d-flex mx-4'>
                      <div className='me-4'>
                        <img src={CHATBOX_LOGO} alt='chatbox-logo' width='50' height='50' />
                      </div>
                      <div className='w-100'>
                        <div className='d-flex flex-wrap align-items-center  justify-content-between w-100'>
                          <div className='width200'>
                            <label className='mb-0 opensans-regular color-theme-darkLightblue font14'>Name:</label>
                            <p className='m-0 opensans-medium font14 text-white word-break'>{obj?.name}</p>
                          </div>
                          <div className='width150'>
                            <label className='mb-0 opensans-regular color-theme-darkLightblue font14'>Number:</label>
                            <p className='m-0 opensans-medium font14 text-white word-break'>{obj?.phone_number}</p>
                          </div>
                          <div className='width200'>
                            <label className='mb-0 opensans-regular color-theme-darkLightblue font14'>Email:</label>
                            <p className='m-0 opensans-medium font14 text-white word-break'>{obj?.email}</p>
                          </div>
                          <div className='width150'>
                            <label className='mb-0 opensans-regular color-theme-darkLightblue font14'>Date:</label>
                            <p className='m-0 opensans-medium font14 text-white'>{formattedDates[index]}</p>
                          </div>
                          <div>
                            <label className='mb-0 opensans-regular color-theme-darkLightblue font14'>Status:</label>
                            <div class='dropdown select-dropdown' id='custom-select'>
                              <button
                                className='btn btn-secondary custom-dropdown-toggle p-0 border-0'
                                type='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                style={{ width: '145px', height: '25px' }}
                              >
                                {leadStatus.find((label) => label.id === obj.activeID)?.status_name}
                                <span>{(!obj.activeID && leadStatus.find((label) => obj.status === label.id)?.status_name) || (!obj.activeID && '-')}</span>
                              </button>

                              <ul className='dropdown-menu' id='dropdown-menu' style={{ width: '145px' }}>
                                {leadStatus.map((label, index) => (
                                  <li className='dropdown-item' key={index}>
                                    <div className='dropdown-item-list w-100'>
                                      <button className='btn text-white text-start px-0 w-100' onClick={(e) => handleStatusChange(e, obj?.id, label.id)}>
                                        <span className=''>{label.status_name}</span>
                                      </button>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className='cursor-pointer' onClick={() => handleDelateLead(obj?.id)}>
                            <img src={DELETE_ICON} alt='delete-icon' />
                          </div>
                        </div>
                        {obj?.lead_summary && (
                          <div className=' mt-4'>
                            <h6 className='opensans-bold font16 m-0 mb-3 lead-summary-color'>Conversation Summary:</h6>
                            <div className='d-flex align-items-center justify-content-between m-0'>
                              <div className='knowledge-pair-body w-100 me-4 lead-overflow ' style={{ height: '90px', overflowY: 'auto' }}>
                                {isEditing[obj.id] ? (
                                  <textarea
                                    placeholder='Update Summary'
                                    className='opensans-regular font14 text-justify color-theme-darkLightblue px-4 py-3 h-100 lead-overflow'
                                    value={editedSummary[obj.id] || ''}
                                    onChange={(e) => handleSummaryChange(e, obj.id)}
                                    autoFocus
                                  />
                                ) : (
                                  <p className='opensans-regular font14 text-justify color-theme-darkLightblue m-0 px-4 py-3'>{obj?.lead_summary}</p>
                                )}
                              </div>

                              <div className='text-white knowledge-pair-icon text-left text-md-center'>
                                {isEditing[obj.id] ? (
                                  <button className='btn p-0' type='button' onClick={() => handleSaveClick(obj.id)}>
                                    <FaSave />
                                  </button>
                                ) : (
                                  <button className='btn p-0' type='button' onClick={() => handleEditClick(obj.id, obj?.lead_summary)}>
                                    <FaEdit />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {getLeadData?.length > 0 && obj?.id === leadId && chatData && (
                          <div className='lead-summary lead-overflow mt-4'>
                            {getLeadData?.map((obj, index) => (
                              <div className='mb-4' key={index}>
                                <div className='d-flex align-items-center justify-content-end mt-3'>
                                  <div className='chatbox-user-quetext '>
                                    <p className='m-0'>{obj?.prmt}</p>
                                  </div>
                                </div>
                                <div className='d-flex align-items-center editdata-chatbox mt-3'>
                                  {obj?.text?.length > 0 && (
                                    <div className='chatbox-user-replytext '>
                                      <p className='m-0'>
                                        <Linkify
                                          componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target='blank' href={decoratedHref} key={key}>
                                              {decoratedText}
                                            </a>
                                          )}
                                        >
                                          {obj?.text}
                                        </Linkify>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className='text-center mt-4'>
                          <h6 className='opensans-bold font16 m-0 color-theme-darkLightblue cursor-pointer'>
                            Open Chat History
                            <span onClick={() => handleToggleChatHistory(obj)}>
                              <img src={obj?.id === leadId && chatData ? UP_ARROW_ICON : DOWN_ARROW_ICON} alt='arrow-icon' className='ms-3' />
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='row m-0 mt-5 px-md-5 px-sm-3 px-3'>
              <div className='chatbox-field bg-themeLightBlue'>
                <p className='text-center color-theme-darkLightblue m-0' style={{ fontSize: '20px' }}>
                  No Records Found
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
    </>
  );
};

export default MyLeads;
