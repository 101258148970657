import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ConversationChart = (props) => {
  const state = {
    series: [
      {
        name: 'Conversation',
        data: props?.data?.data,
      },
    ],
    options: {
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#0167E1'],
      },

      chart: {
        height: 300,
        type: 'area',
        stacked: true,
        toolbar: {
          show: false,
          autoSelected: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: '18px',
          colors: ['#ffffff'],
        },
      },
      fill: {
        type: 'gradient', // Use 'gradient' for line type fill
        colors: ['#1d3f68e0', '#0DEFF6'], // Set the colors for the gradient fill
        opacity: 0.3,
        gradient: {
          gradientToColors: ['#0167E1', '#1d3f68e0'],
          inverseColors: true,
          shadeIntensity: 1,
          type: 'vertical',
          stops: [0, 90, 100],
        },
      },
      grid: {
        show: true,
        borderColor: '#5356fb88',
        strokeDashArray: 5,

        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: props?.data?.category,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        labels: {
          style: {
            colors: '#ffffff', // Set the label color to white
            fontSize: '14px', // You can adjust the font size as needed
          },
        },

        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <div className='react-chart'>
      <ReactApexChart options={state.options} series={state.series} type='area' height={320} />
    </div>
  );
};

export default ConversationChart;
