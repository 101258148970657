import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import USERS_ICON from '../assets/icons/ChirpfloIcon/Total-user-icon.svg';
import MESSAGES_ICON from '../assets/icons/ChirpfloIcon/Msg-bot-icon.svg';
import { toast } from 'react-toastify';
import { clearLocalStorage, getLocalStorage, postWithToken } from '../API/Api';
import { useNavigate } from 'react-router-dom';
import ConversationChart from './ConversationChart';
import ContactChart from './ContactChart';
import CONVERSATIONS_ICON from '../assets/icons/ChirpfloIcon/Conversations-icon.svg';
import CONTACT_ICON from '../assets/icons/ChirpfloIcon/Contact-icon.svg';
import CALENDAR_ICON from '../assets/icons/ChirpfloIcon/Calendar-icon.svg';
import Loader from '../components/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Header from '../components/Header';
import { CircularProgressbar } from 'react-circular-progressbar';
import NewUserChart from './NewUserChart';

const DashboardPerformance = () => {
  const Navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [dashData, setDashData] = useState('');
  const [userCount, setUserCount] = useState('');
  const [userPercent, setUserPercent] = useState('');
  const [messageToBot, setMessageToBot] = useState('');
  const [messageToBotPer, setMessageToBotPer] = useState('');
  const [messageFromBot, setMessageFromBot] = useState('');
  const [messageFromBotPer, setMessageFromBotPer] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let newStartDate = startDate != null ? moment(startDate).format('YYYY-MM-DD') : null;
  let newEndDate = endDate != null ? moment(endDate).format('YYYY-MM-DD') : null;

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setLoader(false);
      getDashboardData(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, [startDate, endDate]);

  const getDashboardData = (token) => {
    let body = {
      filter: { start: newStartDate, end: newEndDate },
    };
    postWithToken('DashboardAPI', token, body)
      .then((response) => {
        if (response.status == 200) {
          setDashData(response.data);
          setUserCount(response?.data?.user?.count);
          setUserPercent(response?.data?.user?.since_last_month);
          setMessageToBot(response?.data?.message_to_bot?.count);
          setMessageToBotPer(response?.data?.message_to_bot?.since_last_month);
          setMessageFromBot(response?.data?.message_from_bot?.count);
          setMessageFromBotPer(response?.data?.message_from_bot?.since_last_month);
        } else if (response.status == 400) {
          toast.error(response.message);
        } else if (response.code == 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const handleCalendarIconClick = (data) => {
    if (data === 'startDateIcon') {
      startDateRef.current.setFocus();
    } else {
      endDateRef.current.setFocus();
    }
  };

  return (
    <>
      {loader ? (
        <div className='loader-center'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='dashboard-performance-card '>
            <div className='chatbot-fixed header-responsive'>
              <div className='d-block d-sm-flex flex-wrap align-items-center justify-content-between'>
                <div className=''>
                  <h3 className='opensans-bold mb-0'>Analytics</h3>
                </div>
                <div className='d-flex justify-content-end responsive-header-width'>
                  <Header />
                </div>
              </div>
            </div>
            {/* <div className='row m-0 px-xl-3 px-lg-3 px-md-3 px-sm-2 px-xs-2'>
              <div className='col-xl-12 col-sm-12 mb-xl-0 mb-4 mt-3'>
                <div className='db-header-raw align-items-center flex-wrap pt-3'>
                  <span className='text-white opensans-bold'>Filter data from</span>
                  <div className='ms-2 position-relative'>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText='Start date' isClearable className='m-0' ref={startDateRef} />
                    {!startDate && <img src={CALENDAR_ICON} alt='Calendar-icon' className='calender-icon' onClick={() => handleCalendarIconClick('startDateIcon')} />}
                  </div>
                  <span className='text-white opensans-bold mx-2'>to</span>
                  <div className='position-relative'>
                    <DatePicker
                      selected={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText='End date'
                      isClearable
                      className='m-0'
                      ref={endDateRef}
                    />
                    {!endDate && <img src={CALENDAR_ICON} alt='Calendar-icon' className='calender-icon' onClick={() => handleCalendarIconClick('endDateIcon')} />}
                  </div>
                </div>
              </div>
            </div> */}
            <div className='row m-0 px-xl-3 px-lg-3 px-md-3 px-sm-2 px-xs-2'>
              <div className='col-12 mb-4 mt-3'>
                <div className='d-flex align-items-center flex-wrap pt-3 date-filter-wrapper'>
                  <span className='text-white fw-bold me-2'>Filter data from</span>
                  <div className='position-relative date-picker-wrapper'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText='Start date'
                      isClearable
                      className='form-control m-0'
                      ref={startDateRef}
                    />
                    {!startDate && (
                      <img src={CALENDAR_ICON} alt='Calendar icon' className='calendar-icon position-absolute' onClick={() => handleCalendarIconClick('startDateIcon')} />
                    )}
                  </div>
                  <span className='text-white fw-bold mx-2'>to</span>
                  <div className='position-relative date-picker-wrapper'>
                    <DatePicker
                      selected={endDate}
                      minDate={startDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText='End date'
                      isClearable
                      className='form-control m-0'
                      ref={endDateRef}
                    />
                    {!endDate && <img src={CALENDAR_ICON} alt='Calendar icon' className='calendar-icon position-absolute' onClick={() => handleCalendarIconClick('endDateIcon')} />}
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 pt-5 px-xl-3 px-lg-3 px-md-3 px-sm-2 px-xs-2'>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-xl-0 mb-4'>
                <div className='custom-hover'>
                  <div className='card'>
                    <div className='card-header p-0'>
                      <div className='db-icons-fr'>
                        <img src={USERS_ICON} className='img-fluid' alt='users-icon' />
                      </div>
                      <div className='text-end'>
                        <p className='text-sm mb-0 opensans-regular'>Total new users</p>
                        <h4 className='mb-0 text-white opensans-regular'>{userCount.toLocaleString()}</h4>
                      </div>
                    </div>
                    <NewUserChart />
                    <div className='card-footer p-0 text-center mt-auto'>
                      <hr className='dark horizontal my-1 hr-custom' />
                      <p className='mb-0 text-grey opensans-regular'>
                        <span className={`text-green opensans-bold arrow-position ${userPercent >= 0 ? '' : 'text-red'}`}>
                          {userPercent}%{userPercent >= 0 ? <BsArrowUp /> : <BsArrowDown />}
                        </span>
                        &nbsp; Since last month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-xl-0 mb-4'>
                <div className='custom-hover'>
                  <div className='card'>
                    <div className='card-header p-0'>
                      <div className='db-icons-fr'>
                        <img src={MESSAGES_ICON} className='img-fluid' alt='messages-icon' />
                      </div>
                      <div className='text-end'>
                        <p className='text-sm mb-0 opensans-regular'>Message to bots</p>
                        <h4 className='mb-0 text-white opensans-regular'>{messageToBot.toLocaleString()}</h4>
                      </div>
                    </div>
                    <div className='dashboard-count-data'>
                      <CircularProgressbar
                        text={messageToBot.toLocaleString() || '0'}
                        value={0}
                        strokeWidth={3}
                        styles={{
                          text: {
                            fill: '#21E7EF',
                            fontSize: '23px',
                            fontWeight: '600',
                          },
                        }}
                      />
                    </div>
                    <div className='card-footer p-0 text-center mt-auto'>
                      <hr className='dark horizontal my-1 hr-custom' />
                      <p className='mb-0 text-grey opensans-regular'>
                        <span className={`text-green opensans-bold arrow-position ${messageToBotPer >= 0 ? '' : 'text-red'}`}>
                          {messageToBotPer}%{messageToBotPer >= 0 ? <BsArrowUp /> : <BsArrowDown />}
                        </span>
                        &nbsp; Since last month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-xl-0 mb-4'>
                <div className='custom-hover'>
                  <div className='card'>
                    <div className='card-header p-0'>
                      <div className='db-icons-fr'>
                        <img src={MESSAGES_ICON} className='img-fluid' alt='messages-icon' />
                      </div>
                      <div className='text-end'>
                        <p className='text-sm mb-0 opensans-regular'>Message from bots</p>
                        <h4 className='mb-0 text-white opensans-regular'>{messageFromBot.toLocaleString()}</h4>
                      </div>
                    </div>
                    <div className='dashboard-count-data'>
                      <CircularProgressbar
                        text={messageFromBot.toLocaleString() || '0'}
                        value={0}
                        strokeWidth={3}
                        styles={{
                          text: {
                            fill: '#21E7EF',
                            fontSize: '23px',
                            fontWeight: '600',
                          },
                        }}
                      />
                    </div>
                    <div className='card-footer p-0 text-center mt-auto'>
                      <hr className='dark horizontal my-1 hr-custom' />
                      <p className='mb-0 p-0 opensans-regular'>
                        <span className={`text-green opensans-bold arrow-position ${messageFromBotPer >= 0 ? '' : 'text-red'}`}>
                          {messageFromBotPer}%{messageFromBotPer >= 0 ? <BsArrowUp /> : <BsArrowDown />}
                        </span>
                        &nbsp; Since last month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='row mt-5 mx-0'> */}
            <div className='row m-0 pt-5 px-xl-3 px-lg-3 px-md-3 px-sm-2 px-xs-2'>
              <div className='col-xl-6 col-lg-6 col-sm-12 mb-xl-0 mb-4'>
                <div className='custom-hover'>
                  <div className='charts-card'>
                    <div className='card-box-text'>
                      {/* <img src={CHATBOX_ICON} alt='chatbox-icon' />
                    <h1 className='opensans-bold text-skyBlue me-2 my-0'>{dashData?.conversations?.count}</h1>
                    <h4 className='opensans-bold my-0'>Conversations</h4> */}
                      <div className='db-icons-fr'>
                        <img src={CONVERSATIONS_ICON} className='img-fluid' alt='chatbox-icon' />
                      </div>
                      <div className='text-end'>
                        <p className='text-sm mb-0 opensans-regular'>Conversations</p>
                        <h5 className='mb-0 text-white opensans-bold'>{dashData?.conversations?.count}</h5>
                      </div>
                    </div>
                    <ConversationChart data={dashData?.conversations} />
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-sm-12 mb-xl-0 mb-4'>
                <div className='custom-hover'>
                  <div className='charts-card'>
                    <div className='card-box-text'>
                      {/* <img src={CONTACT_ICON} alt='contact-icon' />
                    <h1 className='opensans-bold text-skyBlue me-2 my-0'>{dashData?.contact?.count}</h1>
                    <h4 className='opensans-bold my-0'>Contacts</h4> */}

                      <div className='db-icons-fr'>
                        <img src={CONTACT_ICON} className='img-fluid' alt='chatbox-icon' />
                      </div>
                      <div className='text-end'>
                        <p className='text-sm mb-0 opensans-regular'>Contacts</p>
                        <h5 className='mb-0 text-white opensans-bold'>{dashData?.contact?.count}</h5>
                      </div>
                    </div>
                    <ContactChart data={dashData?.contact} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardPerformance;
