import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import initFacebookSDK from './initFacebookSDK';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

initFacebookSDK().then(() => {
  root.render(
    <>
      <App />
    </>
  );
});

reportWebVitals();
