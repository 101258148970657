import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { FiSearch } from 'react-icons/fi';
import THANK_YOU from '../../assets/icons/ChirpfloIcon-V2.0/complated-icon.svg';
import { getLocalStorage, getWithToken } from '../../API/Api';
import Loader from '../../components/Loader';

const SubThankyou = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState('');
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setLoader(true);
      setTimeout(() => {
        getSubscriptionDetails(JSON.parse(token));
      }, 2000);
    }
  }, []);

  const getSubscriptionDetails = async (token) => {
    setLoader(true);
    try {
      const response = await getWithToken('get_subscription', token);
      if (response?.subscription) {
        setSubscriptionDetails(response?.subscription);
        setLoader(false);
      } else {
        console.log('No products found in the response.');
        setLoader(false);
      }
    } catch (error) {
      console.error('Error while fetching subscription details:', error);
      setLoader(false);
    }
  };

  return (
    <>
      <div className='intelichat-conatiner-right'>
        <div className='chatbot-container'>
          <div className='chatbot-fixed header-responsive'>
            <div className='d-block d-sm-flex flex-wrap align-items-center justify-content-between'>
              <h3 className='opensans-bold mb-0'>Your Subscription</h3>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='search-lead input-group'>
                  <span className='input-group-text' id='basic-addon1'>
                    <FiSearch />
                  </span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search here'
                    aria-label='Username'
                    aria-describedby='basic-addon1'
                    // value={search}
                    // onChange={(e) => handleSearch(e)}
                  />
                </div>
                <div className='d-flex justify-content-end responsive-header-width'>
                  <Header />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='m-0 mt-5 px-md-5 px-sm-3 px-3 mb-5'>
          <div className='mb-5'>
            <h3 className='opensans-semibold text-white'>
              <span className='lead-summary-color opensans-semibold'>Chirpflo</span> Plans Made Just Right for Your Needs
            </h3>
            <div className='d-flex flex-wrap justify-content-end align-items-center '>
              {/* <p className='font14 color-theme-darkLightblue opensans-regular m-0'>Find your perfect plan now</p> */}
              <div className='stepper-wrapper'>
                <div className='stepper-item completed'>
                  <div className='step-name mb-2'>Add-Ons</div>
                  <div className='step-counter'></div>
                </div>
                <div className='stepper-item completed'>
                  <div className='step-name mb-2'>Pay</div>
                  <div className='step-counter'></div>
                </div>
                <div className='stepper-item completed'>
                  <div className='step-name mb-2'>Confirmed</div>
                  <div className='step-counter'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='row m-0 mt-5 mb-5 justify-content-center'>
            <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='chatbox-field bg-themeLightBlue'>
                <div className='text-center'>
                  <img src={THANK_YOU} alt='complated-img' />
                  <h2 className='text-white opensans-medium mt-3'>Thank You for Your Subscription!</h2>
                  <p className='font14 text-white opensans-regular mb-0'>
                    Your<span className='lead-summary-color opensans-regular'> {subscriptionDetails?.plan} </span>Plan is Successfully Activated
                  </p>
                </div>
                <div className='container mt-5 p-0 thankyou-sub'>
                  <div>
                    <p className='font18 mb-0 lead-summary-color opensans-bold'>Order Summary</p>
                    <div className='d-flex justify-content-center'>
                      <ul className='ps-0 mt-3 w-100'>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Plan Purchased</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className='ms-4'>{subscriptionDetails?.plan}</span>
                          </div>
                        </li>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Price</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className=' ms-4'>${subscriptionDetails?.price}</span>
                          </div>
                        </li>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Billing Cycle</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className='ms-4'>{subscriptionDetails?.billing_cycle}</span>
                          </div>
                        </li>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Next Billing Date</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className='ms-4'>{subscriptionDetails?.next_billing_date}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <p className='font18 mb-0 lead-summary-color opensans-bold'>Payment Details</p>
                    <div className='d-flex justify-content-center'>
                      <ul className='ps-0 mt-3 w-100'>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Payment Method</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className='ms-4'>{subscriptionDetails?.payment_method}</span>
                          </div>
                        </li>
                        <li className='list-group-item d-flex justify-content-between text-start'>
                          <div className='col-5 opensans-regualer color-theme-darkLightblue'>
                            <strong>Last 4 Digits of Card</strong>
                          </div>
                          <div className='col-5 opensans-regualer text-white'>
                            :<span className=' ms-4'>{subscriptionDetails?.last_four_digit}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
    </>
  );
};

export default SubThankyou;
